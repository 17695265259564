import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Constants from "../../constants";

const initialState = {
    massDelateList : [],
    loader:true
    
}

export const MassGetDeleteTableJsonFunction = createAsyncThunk(
    'massDelete/MassDeleteSlice',async(credentials) => {
        try{
            const response = await fetch(Constants.Api.commonUrl+'GetDeletePersonJSON?Token='+credentials.token+'&category='+credentials.category+'&Period='+credentials.period,{
                
                method : 'GET'
            })
            const data = await response.json()
            return data
        }
        catch(err){
            return console.log('AccountJSON',err)
        }
    }
)

export const MassPersonDeleteTableJsonFunction = createAsyncThunk(
    'massDelete/MassDeleteslice',async(credentials) => {
        try{
            const response = await fetch(Constants.Api.commonUrl+'DeletePersonJSON?Token='+credentials.token+'&category='+credentials.category+'&Period='+credentials.period,{
                
                method : 'GET'
            })
            const data = await response.json()
            return data
        }
        catch(err){
            return console.log('AccountJSON',err)
        }
    }
)

export const MassDelateSlice = createSlice({
    name : 'MassdelateSlice',
    initialState,
    reducers : {
        // triggerRefresh : (state, action) => {
        // }
    },
    extraReducers : {
        [MassGetDeleteTableJsonFunction.pending] : (state, action) => {
            state.loader = true
        },
        [MassGetDeleteTableJsonFunction.fulfilled] : (state, action) => {
            state.clientList = action['payload']['Result']['Client'];
            state.loader = true;
        },
        [MassGetDeleteTableJsonFunction.rejected] : (state, action) => {
            state.loader = true;
            
        },
        [MassPersonDeleteTableJsonFunction.pending] : (state, action) => {
            state.loader = true
        },
        [MassPersonDeleteTableJsonFunction.fulfilled] : (state, action) => {
            state.clientList = action['payload']['Result']['Client'];
            state.loader = true;
        },
        [MassPersonDeleteTableJsonFunction.rejected] : (state, action) => {
            state.loader = true;
            
        },
    }
})



export const {} = MassDelateSlice.actions
export default MassDelateSlice.reducer


