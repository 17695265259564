import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    clientInformation : {},
    recipientContainer : {},
    messgaeContainer : {},
    tabDisable: {
        one: false,
        two: true,
        three: true,
        four: true,
    }
}

const SendQuestionnaireSlice = createSlice({
    name : "setup/sendQuestionnaireSlice",
    initialState,
    reducers : {
        setSelectedClientInformation : (state, action) => {
            state.clientInformation = action.payload
        },
        setRecipientContainer : (state, action) => {
            state.recipientContainer = action.payload
        },
        setMessageContainer : (state, action) => {
            state.messgaeContainer = action.payload
        },
        resetSlice : (state, action) => {
            state.clientInformation = {}
            state.recipientContainer = []
            state.messgaeContainer = {}
    
        },
        setTabDisable : (state, action) => {
            state.tabDisable = action.payload;
        }
    },
    extraReducers : {

    }
})

export const {setSelectedClientInformation, setRecipientContainer,setMessageContainer, resetSlice, setTabDisable} = SendQuestionnaireSlice.actions
export default SendQuestionnaireSlice.reducer