import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Constants from '../../constants'
const initialState = {
  teamMemLoad:false,
  teamLoad:false,
  isLoading: false,
  teamMemGrid: false,
  teamJson: {},
  teamId:'',
  commonTeamId:'',
  publishLoader:false,
  publishDataLoader:false,
  teamMemberEdit: false,
};


export const TeamJsonFn = createAsyncThunk(
  "teams/TeamJsonFn", async (token) => {
    try {
      const response = await fetch(Constants.Api.commonUrl + 'TeamsJSON?Token=' + token.token + '&Criteria=%', {
        method: 'GET'
      });
      const data = await response.json()
      return data;
    } catch (err) {
      return console.log(err);
    }
  }
);

export const addExtTeamMemFn = createAsyncThunk(
  "teams/addExtTeamMemFn", async (params) => {
    try {
      const response = await fetch(Constants.Api.commonUrl + 'AddTeamMemberJSON?Token=' + params.token + '&TeamID=' + params?.teamId + '&PersonID=' + params?.personId, {
        method: 'GET'
      });
      const data = await response.json()
      return data;
    } catch (err) {
      return console.log(err);
    }
  }
);


export const SearchJSON = createAsyncThunk(
  "teams/SearchJSON", async (params) => {
    console.log('checkSearchJson', params)
    try {
      const response = await fetch(Constants.Api.commonUrl + 'SearchJSON?Token=' + params.token + '&Client=' + params.client + '&Criteria=' + params.criteria, {
        method: 'GET'
      });
      const data = await response.json()
      return data;
    } catch (err) {
      return console.log(err);
    }
  }
);
export const SearchJSONFromDialog = createAsyncThunk(
  "teams/SearchJSONFromDialog", async (params) => {
    console.log('checkSearchJson', params)
    try {
      const response = await fetch(Constants.Api.commonUrl + 'SearchJSON?Token=' + params.token + '&Client=' + params.client + '&Criteria=' + params.criteria, {
        method: 'GET'
      });
      const data = await response.json()
      return data;
    } catch (err) {
      return console.log(err);
    }
  }
);
export const SearchJSONWithAdd = createAsyncThunk(
  "teams/SearchJSONWithAdd", async (params) => {
    console.log('checkSearchJson', params)
    try {
      const response = await fetch(Constants.Api.commonUrl + 'SearchJSON?Token=' + params.token + '&Client=' + params.client + '&Criteria=' + params.criteria, {
        method: 'GET'
      });
      const data = await response.json()
      return data;
    } catch (err) {
      return console.log(err);
    }
  }
);


export const addTeamMemServer = createAsyncThunk(
  "teams/addTeamMemServer", async (params) => {
    try {
      const response = await fetch(Constants.Api.commonUrl + 'AddNewTeamMemberJSON?Token=' + params.token + '&TeamID=' + params.teamId + '&Firstname=' + params.firstName + '&Surname=' + params.surName + '&Email=' + params.email, {
        method: 'GET'
      });
      const data = await response.json()
      return data;
    } catch (err) {
      return console.log(err);
    }
  }
);
export const deleteTeamMemServer = createAsyncThunk(
  "teams/deleteTeamMemServer", async (params) => {
    console.log('checkParamsParams', params)
    try {
      const response = await fetch(Constants.Api.commonUrl + 'RemoveTeamMemberJSON?Token=' + params?.token + '&TeamID=' + params?.teamId + '&PersonID=' + params?.personId, {
        method: 'GET'
      });
      const data = await response.json()
      return data;
    } catch (err) {
      return console.log(err);
    }
  }
);

export const addTeamServer = createAsyncThunk(
  "teams/addTeamServer", async (params) => {
    console.log('checkParamsParams', params)
    try {
      const response = await fetch(Constants.Api.commonUrl + 'AddTeamJSON?Token=' + params.userToken + '&TeamName=' + encodeURIComponent(params.teamName) + '&Active=' + params.teamStatus, {
        method: 'GET'
      });
      const data = await response.json()
      return data;
    } catch (err) {
      return console.log(err);
    }
  }
);
export const updateTeamServer = createAsyncThunk(
  "teams/updateTeamServer", async (params) => {
    console.log('checkParamsParams', params)
    try {
      const response = await fetch(Constants.Api.commonUrl + 'UpdateTeamJSON?Token=' + params.userToken + '&TeamID=' + params.Team_Id + '&TeamName=' + params.teamName + '&Active=' + params.teamStatus, {
        method: 'GET'
      });
      const data = await response.json()
      return data;
    } catch (err) {
      return console.log(err);
    }
  }
);
export const teamsStatusServer = createAsyncThunk(
  "teams/teamsStatusServer", async (params) => {
    try {
      const response = await fetch(Constants.Api.commonUrl + 'TeamStatusJSON?Token=' + params.userToken + '&TeamID=' + params.team_id + '&PersonID=%', {
        method: 'GET'
      });
      const data = await response.json()
      return data;
    } catch (err) {
      return console.log(err);
    }
  }
);
export const removeTeamFn = createAsyncThunk(
  "teams/removeTeamFn", async (params) => {

    try {
      const response = await fetch(Constants.Api.commonUrl + 'RemoveTeamJSON?Token=' + params.token + '&TeamID=' + params.teamId, {
        method: 'GET'
      });
      const data = await response.json()
      return data;
    } catch (err) {
      return console.log(err);
    }
  }
);
export const PublishedTeamMapStatusJSON = createAsyncThunk(
  "teams/PublishedTeamMapStatusJSON", async (params) => {

    try {
      const response = await fetch(Constants.Api.commonUrl + 'PublishedTeamMapStatusJSON?Token=' + params.userToken + '&TeamID=' + params.team_id, {
        method: 'GET'
      });
      const data = await response.json()
      return data;
    } catch (err) {
      return console.log(err);
    }
  }
);

export const PublishTeamMapReportJSON = createAsyncThunk(
  "teams/PublishTeamMapReportJSON", async (params) => {

    try {
      const response = await fetch(Constants.Api.commonUrl + 'PublishTeamMapReportJSON?Token=' + params.userToken + '&TeamID=' + params.team_id +'&Language='+params?.language+'&Profile='+params.profile , {
        method: 'GET'
      });
      const data = await response.json()
      return data;
    } catch (err) {
      return console.log(err);
    }
  }
);

export const UpdateTeamMemberJSON = createAsyncThunk(
  "teammember/UpdateTeamMemberJSON", async(credentials) => {
    try {
      const response = await fetch(Constants.Api.commonUrl+'UpdateTeamMemberJSON?Token='+credentials.token+'&TeamID='+credentials.teamId+'&PersonID='+credentials.personId+'&Firstname='+credentials.firstName+'&Surname='+credentials.surName+'&Email='+credentials.email, {
        method: 'get'
      })
      const data = await response.json()
      return data
    } catch(err) {
      return err
    }
  }
)


const teamsSlice = createSlice({
  name: 'teams',
  initialState,
  reducers: {
    setTeamId: (state, action) => {
      
      state.teamId = action.payload
  },
  setCommonTeamId:(state,action) =>{
    state.commonTeamId=action.payload
  }
    // setUserName: (state, action) => {
    //     state.userName = action.payload
    // },
    // setUserPassword: (state, action) => {
    //   state.password = action.payload
    // }
  },
  extraReducers: {
    [teamsStatusServer.pending]: (state, action) => {
      state.teamMemLoad=true
    },
    [teamsStatusServer.fulfilled]: (state, action) => {
      // state.teamJson = action.payload.Result
      state.teamMemLoad = false
      // state.teamJson = action.payload.Result
    },
    [teamsStatusServer.rejected]: (state, action) => {
      state.teamMemLoad = false

    },



    [TeamJsonFn.pending]: (state, action) => {
      state.teamLoad=true
    },
    [TeamJsonFn.fulfilled]: (state, action) => {
      // state.teamJson = action.payload.Result
      state.teamLoad = false
      // state.teamJson = action.payload.Result
    },
    [TeamJsonFn.rejected]: (state, action) => {
      state.teamLoad = false

    },

    [SearchJSON.pending]: (state, action) => {
      state.isLoading = true
    },
    [SearchJSON.fulfilled]: (state, action) => {
      // state.teamJson = action.payload.Result
      state.isLoading = false
      // state.teamJson = action.payload.Result
    },
    [SearchJSON.rejected]: (state, action) => {
      state.isLoading = false
    },

    [SearchJSONWithAdd.pending]: (state, action) => {
      state.extMemGrid = true
    },
    [SearchJSONWithAdd.fulfilled]: (state, action) => {
      // state.teamJson = action.payload.Result
      state.extMemGrid = false
      // state.teamJson = action.payload.Result
    },
    [SearchJSONWithAdd.rejected]: (state, action) => {
      state.extMemGrid = false
    },

    [removeTeamFn.pending]: (state, action) => {
      // state.isLoading=true
    },
    [removeTeamFn.fulfilled]: (state, action) => {
      // state.isLoading = false
      // state.teamJson = action.payload.Result
    },
    [removeTeamFn.rejected]: (state, action) => {
    },

    [deleteTeamMemServer.pending]: (state, action) => {
      state.teamMemGrid = true

    },
    [deleteTeamMemServer.fulfilled]: (state, action) => {
      state.teamMemGrid = false

      // state.teamJson = action.payload.Result
    },
    [deleteTeamMemServer.rejected]: (state, action) => {
      state.teamMemGrid = false

    },
    [addTeamMemServer.pending]: (state, action) => {
      state.teamMemGrid = true

    },
    [addTeamMemServer.fulfilled]: (state, action) => {
      state.teamMemGrid = false

      // state.teamJson = action.payload.Result
    },
    [addTeamMemServer.rejected]: (state, action) => {
      state.teamMemGrid = false

    },

    [addExtTeamMemFn.pending]: (state, action) => {
      state.teamMemGrid = true

    },
    [addExtTeamMemFn.fulfilled]: (state, action) => {
      state.teamMemGrid = false

      // state.teamJson = action.payload.Result
    },
    [addExtTeamMemFn.rejected]: (state, action) => {
      state.teamMemGrid = false

    },

    [PublishTeamMapReportJSON.pending]: (state, action) => {
      state.publishLoader = true

    },
    [PublishTeamMapReportJSON.fulfilled]: (state, action) => {
      state.publishLoader = false

      // state.teamJson = action.payload.Result
    },

    [PublishTeamMapReportJSON.rejected]: (state, action) => {
      state.publishLoader = false

    },
    [PublishedTeamMapStatusJSON.pending]: (state, action) => {
      state.publishDataLoader = true

    },
    [PublishedTeamMapStatusJSON.fulfilled]: (state, action) => {
      state.publishDataLoader = false

      // state.teamJson = action.payload.Result
    },
    [PublishedTeamMapStatusJSON.rejected]: (state, action) => {
      state.publishDataLoader = false

    },

    [UpdateTeamMemberJSON.pending]: (state, action) => {

    },
    [UpdateTeamMemberJSON.fulfilled]: (state, action) => {

    },
    [UpdateTeamMemberJSON.rejected]: (state, action) => {

    }

  }
})


export const { setTeamId ,setCommonTeamId} = teamsSlice.actions;

export default teamsSlice.reducer;