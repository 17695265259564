import React, { useEffect, useState, useTransition } from 'react';
import { Grid } from '@mui/material';
import DialogWrapper from '../../common-component/common-dialog-wrapper/DialogWrapper';
import CommonDataGrid from '../../common-component/common-data-grid/CommonDataGrid';
import './history-dialog.css'

const HistoryDialog = React.memo((props) => {
    const [historyData, setHistoryData] = useState([])
    const [isPending, startTransition] = useTransition()

    useEffect(() => {
        if (props.data.length !== 0) {
            startTransition(() => {
                let tempData = props.data.map((item, index) => {
                    return { ...item, id: index }
                })
                setHistoryData(tempData)
            })
        }
        else {
            setHistoryData([])
        }
    }, [props.data])

    const columns = [
        {
            field: 'Log_Date',
            headerName: window.gt('Log Date'),
            minWidth: 150,
            flex: 'auto',
            editable: false,
        },
        {
            field: 'Type',
            headerName: window.gt('Type'),
            flex: 'auto',
            editable: false,
        },
        {
            field: 'Description',
            headerName: window.gt('Description'),
            flex: 1,
            editable: false,
        }
    ];
    return (
        <>
            <DialogWrapper open={props.open} title={props.title} show={false} showActions = {false} btnNameOne={props.btnNameOne} clickBtnNameOne={() => window.alert('history dialog button clicked')} clickBtnNameTwo={props.clickBtnNameTwo} btnNameTwo={props.btnNameTwo} close={props.close}>
                <Grid container>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className='history-grid'>
                        <CommonDataGrid loading={isPending} showActions = {false} columns={columns} showCheckBox={false} hideFooter={false} rows={historyData} normalgridheight={false} rowHeightAuto={false} />
                    </Grid>
                </Grid>
            </DialogWrapper>
        </>
    )
})

export default HistoryDialog