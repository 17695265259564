import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Constants from '../../constants'
const initialState = {
   creditsloader:false,
   licenseListLoader:false,
   teamTemplateListLoader:false,
   teamDNAListLoader:false,
   transactionsListLoader:false,
   materialsListLoader:false,
   invoiceLoader:false,
   invoiceListLoader:false
};

const setEditorValue = (getRes) =>{  
  var getmessagevalue =  getRes.replace(/</g,'{');
  getmessagevalue = getmessagevalue.replace(/>/g,'}');
  return getmessagevalue;
}

export const AdminTransactionsJSON = createAsyncThunk(
  "adminInvoice/AdminTransactionsJSON", async (token) => {
    try {
      const response = await fetch(Constants.Api.commonUrl + 'TransactionsJSON?Token=' + token.token +'&CID='+token.cId+'&TransactionDate='+token.transactionDate+'&TransactionID='+token.transactionId+'&InvoiceNumber='+token.invoiceNumber, {
        method: 'GET'
      });
      const data = await response.json()
      return data;
    } catch (err) {
      return console.log(err);
    }
  }
);

export const AdminTransactionsInvoiceJSON = createAsyncThunk(
  "adminInvoice/AdminTransactionsInvoiceJSON", async (token) => {
    try {
      const response = await fetch(Constants.Api.commonUrl + 'TransactionInvoiceJSON?Token=' + token.token +'&CID='+token.cId+'&InvoiceDate='+token.transactionDate+'&InvoiceID='+token.transactionId, {
        method: 'GET'
      });
      const data = await response.json()
      return data;
    } catch (err) {
      return console.log(err);
    }
  }
);

export const generateInvoicePdfJSON = createAsyncThunk(
  "adminInvoice/generateInvoicePdfJSON", async (token) => {
    try {
      const response = await fetch(Constants.Api.commonUrl + 'CreateInvoiceReportJSON?Token=' + token.token +'&InvoiceNo='+token.invoiceNumber, {
        method: 'GET'
      });
      const data = await response.json()
      return data;
    } catch (err) {
      return console.log(err);
    }
  }
);


export const TeamTemplateJSON = createAsyncThunk(
  "adminInvoice/TeamTemplateJSON", async (token) => {
    try {
      const response = await fetch(Constants.Api.commonUrl + 'TeamTemplateJSON?Token=' + token.token +'&CID='+token.cid+'&TemplateID='+'%', {
        method: 'GET'
      });
      const data = await response.json()
      return data;
    } catch (err) {
      return console.log(err);
    }
  }
);

export const TeamDNATemplateJSON = createAsyncThunk(
  "adminInvoice/TeamDNATemplateJSON", async (token) => {
    try {
      const response = await fetch(Constants.Api.commonUrl + 'TeamDNATemplateJSON?Token=' + token?.token +'&CID='+token?.cId+'&TemplateID='+token?.templateId, {
        method: 'GET'
      });
      const data = await response.json()
      return data;
    } catch (err) {
      return console.log(err);
    }
  }
);

export const MaterialAdminJSON = createAsyncThunk(
  "adminInvoice/MaterialAdminJSON", async (token) => {
    try {
      const response = await fetch(Constants.Api.commonUrl + 'MaterialAdminJSON?Token=' + token.token +'&MaterialID='+'%', {
        method: 'GET'
      });
      const data = await response.json()
      return data;
    } catch (err) {
      return console.log(err);
    }
  }
);

export const LicenseAdminJSON = createAsyncThunk(
  "adminInvoice/LicenseAdminJSON", async (token) => {
    try {
      const response = await fetch(Constants.Api.commonUrl + 'LicenseAdminJSON?Token=' + token.token +'&CID='+token?.cId+'&LicenseID='+'%', {
        method: 'GET'
      });
      const data = await response.json()
      return data;
    } catch (err) {
      return console.log(err);
    }
  }
);

export const AddLicenseJSON = createAsyncThunk(
  "adminInvoice/AddLicenseJSON", async (token) => {
    try {
      const response = await fetch(Constants.Api.commonUrl + 'AddLicenseJSON?Token=' + token.token +'&CID='+token?.cId+'&StartDate='+token?.formData?.effectiveDate+'&EndDate='+token?.formData?.expiryDate+'&Comments='+setEditorValue(token?.formData?.comments)+'&Credit='+token?.formData?.credit+'&CreditUsed='+token?.formData?.creditused+'&CreditBalance='+token?.formData?.creditbalance, {
        method: 'GET'
      });
      const data = await response.json()
      return data;
    } catch (err) {
      return console.log(err);
    }
  }
);


export const AddTransactionJSON = createAsyncThunk(
  "adminInvoice/AddLicenseJSON", async (token) => {
    try {

      const response = await fetch(Constants.Api.commonUrl + 'AddTransactionsJSON?Token=' + token.token +'&CID='+token?.cId+'&DESCRIPTION='+token?.formData?.description+'&TRANSACTIONDATE='+token?.formData?.transactionDate+'&INVOICENUMBER='+token?.formData?.invoiceNumber+'&SUPPLIERID='+token?.formData?.supplier+'&LICENSENUMBER='+token?.formData?.licenseNumber+'&AMOUNT='+token?.formData?.units*token?.formData?.unitCost+'&VATAMOUNT='+token?.formData?.vatAmount+'&TOTALAMOUNT='+token?.formData?.totalAmount+'&DATEPAID='+token?.formData?.datePaid+'&UNITS='+token?.formData?.units+'&UNITCOSTS='+token?.formData?.unitCost+'&EXPENSE='+token?.formData?.expense+'&VATABLE='+token?.formData?.fullyVatable, {
        method: 'GET'
      });
      const data = await response.json()
      return data;
    } catch (err) {
      return console.log(err);
    }
  }
);
export const UpdateTransactionJSON = createAsyncThunk(
  "adminInvoice/AddLicenseJSON", async (token) => {
    try {

      const response = await fetch(Constants.Api.commonUrl + 'UpdateTransactionsJSON?Token=' + token.token +'&CID='+token?.cId+'&TRANSACTIONID='+token?.formData?.transactionId+'&DESCRIPTION='+token?.formData?.description+'&TRANSACTIONDATE='+token?.formData?.transactionDate+'&INVOICENUMBER='+token?.formData?.invoiceNumber+'&SUPPLIERID='+token?.formData?.supplier+'&LICENSENUMBER='+token?.formData?.licenseNumber+'&AMOUNT='+token?.formData?.units*token?.formData?.unitCost+'&VATAMOUNT='+token?.formData?.vatAmount+'&TOTALAMOUNT='+token?.formData?.totalAmount+'&DATEPAID='+token?.formData?.datePaid+'&UNITS='+token?.formData?.units+'&UNITCOSTS='+token?.formData?.unitCost+'&EXPENSE='+token?.formData?.expense+'&VATABLE='+token?.formData?.fullyVatable, {
        method: 'GET'
      });
      const data = await response.json()
      return data;
    } catch (err) {
      return console.log(err);
    }
  }
);

export const UpdateLicenseJson = createAsyncThunk(
  "adminInvoice/UpdateLicenseJson", async (token) => {
    try {
      const response = await fetch(Constants.Api.commonUrl + 'UpdateLicenseJSON?Token=' + token.token +'&CID='+token?.cId+'&LicenseNumber='+token?.formData?.licenseNumber+'&StartDate='+token?.formData?.effectiveDate+'&EndDate='+token?.formData?.expiryDate+'&Comments='+setEditorValue(token?.formData?.comments)+'&Credit='+token?.formData?.credit+'&CreditUsed='+token?.formData?.creditused+'&CreditBalance='+token?.formData?.creditbalance, {
        method: 'GET'
      });
      const data = await response.json()
      return data;
    } catch (err) {
      return console.log(err);
    }
  }
);
export const UpdateMaterialJSONServer = createAsyncThunk(
  "adminInvoice/UpdateMaterialJSONServer", async (token) => {
    try {
      const response = await fetch(Constants.Api.commonUrl + 'UpdateMaterialsAdminJSON?Token=' + token.token +'&MaterialID='+token?.formData?.materialId?.material_id+'&Name='+token?.formData?.name+'&Description='+token?.formData?.description+'&Cost='+token?.formData?.cost+'&VAT='+token?.formData?.vat+'&Active='+token?.formData?.active?.code+'&Sorting='+token?.formData?.order, {
        method: 'GET'
      });
      const data = await response.json()
      return data;
    } catch (err) {
      return console.log(err);
    }
  }
);

export const AddMaterialJSONServer = createAsyncThunk(
  "adminInvoice/AddMaterialJSONServer", async (token) => {
    try {
      const response = await fetch(Constants.Api.commonUrl + 'AddMaterialsAdminJSON?Token=' + token.token +'&Name='+token?.formData?.name+'&Description='+token?.formData?.description+'&Cost='+token?.formData?.cost+'&VAT='+token?.formData?.vat+'&Active='+token?.formData?.active?.code+'&Sorting='+token?.formData?.order, {
        method: 'GET'
      });
      const data = await response.json()
      return data;
    } catch (err) {
      return console.log(err);
    }
  }
);

export const CreditUsedJSON = createAsyncThunk(
  "adminInvoice/CreditUsedJSON", async (token) => {
    try {
      const response = await fetch(Constants.Api.commonUrl + 'CreditUsedJSON?Token=' + token.token +'&CID='+token.cId+'&LicenseID='+token.licValue+'&Year='+token.year, {
        method: 'GET'
      });
      const data = await response.json()
      return data;
    } catch (err) {
      return console.log(err);
    }
  }
);

export const AddInvoiceJSON = createAsyncThunk(
  "adminInvoice/AddInvoiceJSON", async (token) => {
    try {
      const response = await fetch(`${Constants.Api.commonUrl}AddInvoiceJSON?Token=${token.token}&CID=${token?.cId}&INVOICE_TYPE=${token?.formData?.invoiceTypeValue}&SUPPLIER_ID=${1}&INVOICE_DATE=${token?.formData?.invoiceDate}&DESCRIPTION=${token?.formData?.description}&DATE_SENT=${token?.formData?.dateSent}&DATE_REMINDED=${token?.formData?.dateRemaindersent}&DATE_RECEIVED=${token?.formData?.dateReceived}&DATE_PAID=${token?.formData?.datePaid}&DATE_DUE=${token?.formData?.paydatedue}&NOTES=${token?.formData?.notes}&WRITE_OFF_DATE=${token?.formData?.writeOffDate}&WRITE_OFF_REASON=${token?.formData?.writeOffReason}&WRITE_OFF_APPROVED_BY=${token?.formData?.writeOffAut}` ,{

        method: 'GET'
      });
      const data = await response.json()
      return data;
    } catch (err) {
      return console.log(err);
    }
  }
);

export const UpdateInvoiceJSON = createAsyncThunk(
  "adminInvoice/UpdateInvoiceJSON", async (token) => {
    try {
      const response = await fetch(`${Constants.Api.commonUrl}UpdateInvoiceJSON?Token=${token.token}&CID=${token?.cId}&INVOICE_NUMBER=${token?.formData?.invoiceNumber}&INVOICE_TYPE=${token?.formData?.invoiceTypeValue}&SUPPLIER_ID=${token?.formData?.supplier_id}&INVOICE_DATE=${token?.formData?.invoiceDate}&DESCRIPTION=${token?.formData?.description}&DATE_SENT=${token?.formData?.dateSent}&DATE_REMINDED=${token?.formData?.dateRemaindersent}&DATE_RECEIVED=${token?.formData?.dateReceived}&DATE_PAID=${token?.formData?.datePaid}&DATE_DUE=${token?.formData?.paydatedue}&NOTES=${token?.formData?.notes}&WRITE_OFF_DATE=${token?.formData?.writeOffDate}&WRITE_OFF_REASON=${token?.formData?.writeOffReason}&WRITE_OFF_APPROVED_BY=${token?.formData?.writeOffAut}`, {
        method: 'GET'
      });
      const data = await response.json()
      return data;
    } catch (err) {
      return console.log(err);
    }
  }
);

const adminInvoiceSlice = createSlice({
  name: 'adminInvoice',
  initialState,
  reducers: {
    setTeamId: (state, action) => {
      
     
  },
  setCommonTeamId:(state,action) =>{
    // state.commonTeamId=action.payload
  }
   
  },
  extraReducers: {
    [CreditUsedJSON.pending]: (state, action) => {
      state.creditsloader=true
    },
    [CreditUsedJSON.fulfilled]: (state, action) => {
      state.creditsloader = false
    },
    [CreditUsedJSON.rejected]: (state, action) => {
      state.creditsloader = false

    },

    [LicenseAdminJSON.pending]: (state, action) => {
      state.licenseListLoader=true
    },
    [LicenseAdminJSON.fulfilled]: (state, action) => {
      state.licenseListLoader = false
    },
    [LicenseAdminJSON.rejected]: (state, action) => {
      state.licenseListLoader = false

    },

    [TeamTemplateJSON.pending]: (state, action) => {
      state.teamTemplateListLoader=true
    },
    [TeamTemplateJSON.fulfilled]: (state, action) => {
      state.teamTemplateListLoader = false
    },
    [TeamTemplateJSON.rejected]: (state, action) => {
      state.teamTemplateListLoader = false

    },

    [TeamDNATemplateJSON.pending]: (state, action) => {
      state.teamDNAListLoader=true
    },
    [TeamDNATemplateJSON.fulfilled]: (state, action) => {
      state.teamDNAListLoader = false
    },
    [TeamDNATemplateJSON.rejected]: (state, action) => {
      state.teamDNAListLoader = false

    },

    [AdminTransactionsJSON.pending]: (state, action) => {
      state.transactionsListLoader=true
    },
    [AdminTransactionsJSON.fulfilled]: (state, action) => {
      state.transactionsListLoader = false
    },
    [AdminTransactionsJSON.rejected]: (state, action) => {
      state.transactionsListLoader = false

    },

    [generateInvoicePdfJSON.pending]: (state, action) => {
      state.invoiceLoader=true
    },
    [generateInvoicePdfJSON.fulfilled]: (state, action) => {
      state.invoiceLoader = false
    },
    [generateInvoicePdfJSON.rejected]: (state, action) => {
      state.invoiceLoader = false

    },

    [AdminTransactionsInvoiceJSON.pending]: (state, action) => {
      state.invoiceListLoader=true
    },
    [AdminTransactionsInvoiceJSON.fulfilled]: (state, action) => {
      state.invoiceListLoader = false
    },
    [AdminTransactionsInvoiceJSON.rejected]: (state, action) => {
      state.invoiceListLoader = false

    },

    [MaterialAdminJSON.pending]: (state, action) => {
      state.materialsListLoader=true
    },
    [MaterialAdminJSON.fulfilled]: (state, action) => {
      state.materialsListLoader = false
    },
    [MaterialAdminJSON.rejected]: (state, action) => {
      state.materialsListLoader = false
    },
  }
})


export const { setTeamId ,setCommonTeamId} = adminInvoiceSlice.actions;

export default adminInvoiceSlice.reducer;