import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Constants from "../../constants";

const initialState = {
    showLoader : false,
    searchData:false,
    lookUpListLoader:false,
    memberShipLoader: false,
    picklistLoader: false,
    massPublishLoader: false,
    massPublishCount: 0,
}

export const PersonSearchResponseFunction = createAsyncThunk(
    'personSearch/personSearchResponse',async(credentials) => {
        try{
            const response = await fetch(Constants.Api.commonUrl+'PersonResponseJSON?Token='+credentials.token+'&PersonID='+credentials.personId,{
                method : 'get'
            })
            const data = await response.json()
            return data
        }
        catch(err){
            return console.log('personSearchResponseJSON',err)
        }
    }
)

export const PersonJsonFunction = createAsyncThunk(
    'personSearch/personJson', async(credentials) => {
        try {
            const response = await fetch(Constants.Api.commonUrl+'PersonJSON?Token='+credentials.token+'&PersonID='+credentials.personId, {
                method : 'get'
            })
            const data = await response.json()
            return data
        }
        catch(err){
            return console.log('personJSON',err)
        }
    }
)

export const UpdatePersonJsonFunction = createAsyncThunk(
    'personSearch/updatePersonJson', async(credentials) => {
        try{
            const response = await fetch(Constants.Api.commonUrl+'UpdatePersonJSON?Token='+credentials.token+'&PersonID='+credentials.personObj.personId+'&FirstName='+credentials.personObj.foreName+'&Surname='+credentials.personObj.surName+'&Gender='+credentials.personObj.gender+'&Email='+credentials.personObj.email+'&JobTitle='+credentials.personObj.jobTitle+'&ContactNumber='+credentials.personObj.mobile+'&ClientID='+credentials.personObj.clientId+'&CurrentSituation='+credentials.personObj.currentSituation,{
                method : 'get'
            })
            const data = await response.json()
            return data
        }
        catch(err){
            return console.log('update person json',err)
        }
    }
)

export const PublishedProfileStatusJsonFunction = createAsyncThunk(
    'personSearch/publishedProfileStatusJson', async(credentials) => {
        try{
            const response = await fetch(Constants.Api.commonUrl+'PublishedProfileStatusJSON?Token='+credentials.token+'&PersonID='+credentials.personId,{
                method : 'get'
            })
            const data = await response.json()
            return data
        }
        catch(err){
            return console.log('Published profile json',err)
        }
    }
)

export const LanguageListJsonFunction = createAsyncThunk(
    'personSearch/languageListJson', async(credentials) => {
        try{
            const response = await fetch(Constants.Api.commonUrl+'PickListJSON?Token='+credentials.token+'&Category='+credentials.category+'&Language='+(credentials.language === undefined ? 'ENG' : credentials.language)+'&Gender=M',{
                method : 'get' 
            })
            const data = await response.json()
            return data
        }
        catch(err){
            return console.log("Language list json",err)
        }
    }
)

export const PublishJsonFunction = createAsyncThunk(
    "personSearch/publishJson", async(credentials) => {
        try{
            const response = await fetch(Constants.Api.commonUrl+'PublishProfileReportJSON?Token='+credentials.token+'&Profile='+credentials.profile+'&PersonId='+credentials.personId+'&Language='+credentials.language, {
                method : 'get'
            })
            const data = await response.json()
            return data
        }
        catch(err){
            return console.log('Publish json error',err)
        }
    }
)

export const PublishedTwoDProfileStatusFunction = createAsyncThunk(
    "personSearch/publishTwoDProfile", async(credentials) => {
        try{
            const response = await fetch(Constants.Api.commonUrl+'Published2DProfileStatusJSON?Token='+credentials.token+'&PersonID='+credentials.personId+'&Period='+credentials.period,{
                method : 'get'
            })
            const data = await response.json()
            return data
        }
        catch(err){
            return console.log("publish 2D profile status err",err)
        }
    }
)

export const PublishedTwoDProfileReportFunction = createAsyncThunk(
    "personSearch/publishTwoDProfileReport", async(credentials) => {
        try{
            const response = await fetch(Constants.Api.commonUrl+'Publish2DProfileReportJSON?Token='+credentials.token+'&PersonID='+credentials.personId+'&FeedbackID='+credentials.feedBackId+'&ExcludeID='+credentials.excludeId+'&Language='+credentials.language,{
                method : 'get'
            })
            const data = await response.json()
            return data
        }
        catch(err){
            return console.log("published 2D profile report error",err)
        }
    }
)

export const DeleteFeedbackPdfFunction = createAsyncThunk(
    'personSearch/deleteFeefback', async(credentials) => {
        try{
            const response = await fetch(Constants.Api.commonUrl+'DeleteDocumentJSON?Token='+credentials.token+'&DocID='+credentials.docId+'&DocType='+credentials.docType,{
                method : 'get'
            })
            const data = await response.json()
            return data
        }
        catch(err){
            return console.log("Delete feedback API err",err)
        }
    }
)

export const adminInvoiceSearchJSON = createAsyncThunk(
    'personSearch/adminInvoiceSearchJSON', async(credentials) => {
        // https://services.clarity4d.com/C4DProfile/c4dapi.asmx/InvoiceSearchJSON?Token=0bb7ed66-3472-4421-884f-8a0075b0b7f7&InvoiceNo=102614192&CustomerName=%&DateSent=ALL&Status=P&CID=677
        try{
            const response = await fetch(Constants.Api.commonUrl+'InvoiceSearchJSON?Token='+credentials.token+'&InvoiceNo='+credentials.invoiceNumber+'&CustomerName='+credentials.customerName+'&DateSent='+credentials.dateSent+'&Status='+credentials.status+'&CID='+credentials.cId,{
                method : 'get'
            })
            const data = await response.json()
            return data
        }
        catch(err){
            return console.log("Delete feedback API err",err)
        }
    }
)

export const LookUpListJsonFunction = createAsyncThunk(
    'personSearch/LookUpListJsonFunction', async(credentials) => {
        try{
            const response = await fetch(Constants.Api.commonUrl+'LookupListJSON?Token='+credentials.token+'&CATEGORY='+credentials.category+'&Language='+credentials?.language+'&Gender='+credentials?.gender+'&CODE=%',{
                method : 'get' 
            })
            const data = await response.json()
            return data
        }
        catch(err){
            return console.log("Lookup list json",err)
        }
    }
)

export const addLookUpServer = createAsyncThunk(
    'personSearch/addLookUpServer', async(credentials) => {
        try{
            const response = await fetch(Constants.Api.commonUrl+'AddUpdateLookupJSON?Token='+credentials.token+'&CATEGORY='+credentials.formData.category+'&LANGUAGE='+credentials.formData.language+'&Gender='+credentials.formData.gender+'&CODE='+credentials.formData.code+'&DESCRIPTION='+credentials.formData.description+'&ACTIVE='+credentials.formData.active+'&SYSTEM='+credentials.formData.system,{
                method : 'get' 
            })
            const data = await response.json()
            return data
        }
        catch(err){
            return console.log("Language list json",err)
        }
    }
)

export const PersonMemberShipJSON = createAsyncThunk(
    'presonSearch/PersonMemberShipJSON', async(credentials) => {
        try {
            const response = await fetch(Constants.Api.commonUrl+'PersonMembershipJSON?Token='+credentials.token+'&PersonID='+credentials.personId, {
                method: 'get'
            })
            const data = await response.json();
            return data;
        }
        catch (err) {
            return err
        }
    }
)

export const PersonSearchSlice = createSlice({
    name : 'personSearchSlice',
    initialState,
    reducers : {
        triggerRefresh : (state, action) => {
        },
        setMassPublishLoader: (state, action) => {
            state.massPublishLoader = action.payload
        },
        setMassPublishCount: (state, action) => {
            state.massPublishCount = action.payload
        },
    },
    extraReducers : {
        [PersonSearchResponseFunction.pending] : (state, action) => {

        },
        [PersonSearchResponseFunction.fulfilled] : (state, action) => {
            
        },
        [PersonSearchResponseFunction.rejected] : (state, action) => {
            
        },

        [PersonJsonFunction.pending] : (state, action) => {

        },
        [PersonJsonFunction.fulfilled] : (state, action) => {
            
        },
        [PersonJsonFunction.rejected] : (state, action) => {
            
        },

        [UpdatePersonJsonFunction.pending] : (state, action) => {

        },
        [UpdatePersonJsonFunction.fulfilled] : (state, action) => {
            
        },
        [UpdatePersonJsonFunction.rejected] : (state, action) => {
            
        },

        [PublishedProfileStatusJsonFunction.pending] : (state, action) => {
            state.showLoader = true
        },
        [PublishedProfileStatusJsonFunction.fulfilled] : (state, action) => {
            state.showLoader = false
        },
        [PublishedProfileStatusJsonFunction.rejected] : (state, action) => {
            state.showLoader = false
        },

        [PublishJsonFunction.pending] : (state, action) => {
            state.showLoader = true
        },
        [PublishJsonFunction.fulfilled] : (state, action) => {
            state.showLoader = false
        },
        [PublishJsonFunction.rejected] : (state, action) => {
            state.showLoader = false
        },

        [PublishedTwoDProfileStatusFunction.pending] : (state, action) => {
            state.showLoader = true
        },
        [PublishedTwoDProfileStatusFunction.fulfilled] : (state, action) => {
            state.showLoader = false
        },
        [PublishedTwoDProfileStatusFunction.rejected] : (state, action) => {
            state.showLoader = false            
        },

        [PublishedTwoDProfileReportFunction.pending] : (state, action) => {
            state.showLoader = true
        },
        [PublishedTwoDProfileReportFunction.fulfilled] : (state, action) => {
            state.showLoader = false
        },
        [PublishedTwoDProfileReportFunction.rejected] : (state, action) => {
            state.showLoader = false
        },

        // adminInvoiceSearchJSON
        [adminInvoiceSearchJSON.pending] : (state, action) => {
            state.searchData = true
        },
        [adminInvoiceSearchJSON.fulfilled] : (state, action) => {
            state.searchData = false
        },
        [adminInvoiceSearchJSON.rejected] : (state, action) => {
            state.searchData = false
        },

        [LookUpListJsonFunction.pending] : (state, action) => {
            state.lookUpListLoader = true
        },
        [LookUpListJsonFunction.fulfilled] : (state, action) => {
            state.lookUpListLoader = false
        },
        [LookUpListJsonFunction.rejected] : (state, action) => {
            state.lookUpListLoader = false
        },

        [PersonMemberShipJSON.pending]: (state, action) => {
            state.memberShipLoader = true
        },
        [PersonMemberShipJSON.fulfilled]: (state, action) => {
            state.memberShipLoader = false
        },
        [PersonMemberShipJSON.rejected]: (state, action) => {
            state.memberShipLoader = false
        },

        [LanguageListJsonFunction.pending]: (state, action) => {
            state.picklistLoader = true
        },
        [LanguageListJsonFunction.fulfilled]: (state, action) => {
            state.picklistLoader = false
        },
        [LanguageListJsonFunction.rejected]: (state, action) => {
            state.picklistLoader = false
        },
    }
})

export const {setMassPublishLoader, setMassPublishCount} = PersonSearchSlice.actions
export default PersonSearchSlice.reducer