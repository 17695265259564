import { configureStore } from "@reduxjs/toolkit";
import loginReducer from './features/login/loginSlice';
import profileStatusReducer from "./features/profile-status/profileStatusSlice";
import personSearchReducer from "./features/person-search/personSearchSlice";
import teamsReducer from './features/teams/teamsSlice'
import emailTemplateReducer from "./features/emailTemplate/emailTemplateSlice";
import invoiceAndCredits from './features/invoiceAndCredits/invoiceAndCreditsSlice'
import reportReducer from './features/report/reportSlice';
import clientStructureReducer from "./features/clientStructure/clientStructureSlice";
import DashboardReducer from "./features/dashboard/DashboardSlice";
import globalSearchReducer from "./features/globalSearch/globalSearchSlice";
import resourceReducer from './features/resource/resourceslice';
import PreferenceReducer from './features/preference/preferenceSlice';
import sendQuestionnaireReducer from "./features/senQuestionnaire/sendQuestionnaireSlice";
import massDeleteReducer from "./features/massDelate/massDeleteSlice";
import companyReducer from "./features/admin-company/companySlice";
import adminInvoiceReducer from "./features/adminInvoices/adminInvoiceSlice"
import adminUserAccountReducer from './features/admin-company-useraccount/admin-company-userAccountSlice';
import adminResourceReducer from './features/admin-Resource/resourceSlice';
import surveyQuestionReducer from "./features/issueSurvey/issueSurveySlice";
import surveyQuestionSlice from "./features/survey-questionnaire/surveyQuestionSlice";
import adminSystemReducer from "./features/admin-system/adminSystemSlice";

export const store = configureStore({
  
    reducer: {
        login: loginReducer,
        profileStatus: profileStatusReducer,
        invoiceAndCredits: invoiceAndCredits,
        personSearch : personSearchReducer,
        teams:teamsReducer,
        emailTemplate:emailTemplateReducer,
        report:reportReducer,
        clientStructure: clientStructureReducer,
        dashboard: DashboardReducer,
        globalSearch:globalSearchReducer,
        resource:resourceReducer,
        Preference:PreferenceReducer,
        sendQuestionnaire: sendQuestionnaireReducer,
        massDelete:massDeleteReducer,
        company:companyReducer,
        adminuserAccount:adminUserAccountReducer,
        adminResource:adminResourceReducer,
        adminInvoice:adminInvoiceReducer,
        survey :surveyQuestionReducer,
        surveySlice: surveyQuestionSlice,
        adminSystem: adminSystemReducer,
    },
  });