import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Constants from "../../constants";

const initialState = {
    globalCompany: {},
    loading: false,
    licenseLoading: false,
    teamDNALoading: false,
    teamTemplateLoading: false,
    companyClientLoading: false,
    companyEmailTemplateLoading: false,
    userLoader:false,
    company:false,
    companySearchLoader: false,
    rightCompanySearchLoader: false,
    updateCompanyLoader: false,
    rightSideGridLoader:false
}

export const CompanyJSON = createAsyncThunk(
    'admin/CompanyJSON', async(credentials) => {
        try{
            const response = await fetch(Constants.Api.commonUrl+'CompanyJSON?Token='+credentials.token+'&CID='+credentials.cId,{
                method: 'get'
            })
            const data = await response.json()
            return data
        }
        catch(err){
            return console.log("company json err",err)
        }
    }
)

export const CompanyBankJSON = createAsyncThunk(
    'admin/CompanyBankJSON', async(credentials) => {
        try{
            const response = await fetch(Constants.Api.commonUrl+'BankJSON?Token='+credentials.token+'&CID='+credentials.cId+'&PayMethod='+credentials.PayMethod,{
                method: 'get'
            })
            const data = await response.json()
            return data
        }
        catch(err){
            return console.log("company json err",err)
        }
    }
)

export const ProfileTemplateJSON = createAsyncThunk(
    'admin/ProfileTemplateJSON', async(credentials) => {
        try{
            const response = await fetch(Constants.Api.commonUrl+'ProfileTemplateJSON?Token='+credentials.token+'&CID='+credentials.cId+'&TemplateID='+credentials.templateId,{
                method: 'get'
            })
            const data = await response.json()
            return data
        }
        catch(err){
            return console.log('Profile templateJSON', err)
        }
    }
)

export const LicenseAdminJSON = createAsyncThunk(
    "admin/LicenseAdminJSON", async(credentials) => {
        try{
            const response = await fetch(Constants.Api.commonUrl+'LicenseAdminJSON?Token='+credentials.token+'&CID='+credentials.cId+'&LicenseID='+credentials.licenseId,{
                method: 'get'
            })
            const data = await response.json()
            return data
        }
        catch(err){
            return console.log("License admin JSON error",err)
        }
    }
)

export const CreditUsedJSON = createAsyncThunk(
    "admin/CreditUsedJSON", async(credentials) => {
        try{
            const response = await fetch(Constants.Api.commonUrl+'CreditUsedJSON?Token='+credentials.token+'&CID='+credentials.cId+'&LicenseID='+credentials.licenseId+'&Year='+credentials.year, {
                method: 'get'
            })
            const data = await response.json()
            return data
        }
        catch(err){
            return console.log("credit used error", err)
        }
    }
)

export const CompanyTemplateJSON = createAsyncThunk(
    "admin/CompanyTemplate", async(credentials) => {
        try{
            const response = await fetch(Constants.Api.commonUrl+'CompanyTemplateJSON?Token='+credentials.token, {
                method: 'get'
            })
            const data = await response.json()
            return data
        }
        catch(err){
            return console.log("CompanyTemplateJSON error", err)
        }
    }
)

export const TeamTemplateJSON = createAsyncThunk(
    "admin/TeamTemplateJSON", async(credentials) => {
        try{
            const response = await fetch(Constants.Api.commonUrl+'TeamTemplateJSON?Token='+credentials.token+'&CID='+credentials.cId+'&TemplateID='+'%', {
                method: 'get'
            })
            const data = await response.json()
            return data
        }
        catch(err){
            return console.log("TeamTemplateJSON error", err)
        }
    }
)

export const TeamDNATemplate = createAsyncThunk(
    "admin/TeamDNATemplate", async(credentials) => {
        try{
            const response = await fetch(Constants.Api.commonUrl+'TeamDNATemplateJSON?Token='+credentials.token+'&CID='+credentials.cId+'&TemplateID='+credentials.templateId, {
                method: 'get'
            })
            const data = await response.json()
            return data
        }
        catch(err){
            return console.log("TeamDNA template error", err)
        }
    }
)

export const AddCompanyJSON = createAsyncThunk(
    "admin/AddCompanyJSON", async(credentials) => {
        try{
            const response = await fetch(Constants.Api.commonUrl+'AddCompanyJSON?Token='+credentials.token+'&Description='+credentials['data']['companyName']+'&Tradename='+credentials['data']['tradeName']+'&Address1='+credentials['data']['address']+'&Address2='+''+'&Address3='+''+'&Address4='+''+'&PostCode='+credentials['data']['postCode']+'&Town='+credentials['data']['town']+'&Region='+credentials['data']['region']+'&Country='+credentials['data']['country']+'&VatNumber='+credentials['data']['vatNumber']+'&ContactNumber='+''+'&ContactName='+credentials['data']['contactName']+'&Telephone='+credentials['data']['telephone']+'&Mobile='+credentials['data']['mobTelephone']+'&EmailAddress='+credentials['data']['emailAddr']+'&PayVAT='+credentials['data']['yesNo']+'&Accredited='+(credentials['data']['compStatus'] !== undefined ? credentials['data']['compStatus']['Code'] : '') +'&BuyCredit='+credentials['data']['orderCredit']+'&ReferralCID='+credentials['data']['referedBy']+'&AccreditationDate='+credentials['data']['accDate']+'&InvoiceAddress='+credentials['data']['invoiceAddr']+'&Marketing='+credentials['data']['perMonth']+'&ValidationMode='+credentials['data']['validationMode']+'&AutoCalculate='+credentials['data']['autoPublish']+'&TeamifyAccount='+credentials['data']['teamify']+'&Parent='+credentials['data']['parent'],{
                method: 'get'
            })
            const data = await response.json()
            return data
        }
        catch(err){
            return console.log("AddCompanyJSON error",err)
        }
    }
)

export const UpdateCompanyJSON = createAsyncThunk(
    "admin/UpdateCompanyJSON", async(credentials) => {
        try{
            const response = await fetch(Constants.Api.commonUrl+'UpdateCompanyJSON?Token='+credentials.token+'&Description='+credentials['data']['companyName']+'&Tradename='+credentials['data']['tradeName']+'&Address1='+credentials['data']['address']+'&Address2='+''+'&Address3='+''+'&Address4='+''+'&PostCode='+credentials['data']['postCode']+'&Town='+credentials['data']['town']+'&Region='+credentials['data']['region']+'&Country='+credentials['data']['country']+'&VatNumber='+credentials['data']['vatNumber']+'&ContactNumber='+''+'&ContactName='+credentials['data']['contactName']+'&Telephone='+credentials['data']['telephone']+'&Mobile='+credentials['data']['mobTelephone']+'&EmailAddress='+credentials['data']['emailAddr']+'&PayVAT='+credentials['data']['yesNo']+'&Accredited='+(credentials['data']['compStatus'] !== undefined ? credentials['data']['compStatus']['Code'] : '')+'&BuyCredit='+credentials['data']['orderCredit']+'&ReferralCID='+credentials['data']['referedBy']+'&AccreditationDate='+credentials['data']['accDate']+'&InvoiceAddress='+credentials['data']['invoiceAddr']+'&Marketing='+credentials['data']['perMonth']+'&ValidationMode='+credentials['data']['validationMode']+'&AutoCalculate='+credentials['data']['autoPublish']+'&TeamifyAccount='+credentials['data']['teamify']+'&Parent='+credentials['data']['parent']+'&CID='+credentials.cId,{
                method: 'get'
            })
            const data = await response.json()
            return data
        }
        catch(err){
            return console.log("Update company JSON error",err)
        }
    }
)

export const AddUpdateProfileTemplateJSON = createAsyncThunk(
    "Admin/AddUpdateProfileTemplateJSON", async(credentials) => {
        try{
            const response = await fetch(Constants.Api.commonUrl+'AddUpdateProfileTemplateJSON?Token='+credentials.token+'&CID='+credentials.cId+'&ProfileTemplate='+credentials.formData.template+'&EffectiveDate='+credentials.formData.effectiveDate+'&EndDate='+credentials.formData.endDate+'&Description='+credentials.formData.profileDescription+'&Credit='+credentials.formData.creditCost+'&Active='+credentials.formData.active,{
                method: 'get'
            })
            const data = await response.json()
            return data
        }
        catch(err){
            return console.log('Add update profile JSON err',err)
        }
    }
)

export const AddUpdateProfileTeamTemplateJSON = createAsyncThunk(
    "Admin/AddUpdateProfileTeamTemplateJSON", async(credentials) => {
        console.log(credentials)
        try{
            const response = await fetch(Constants.Api.commonUrl+'AddUpdateTeamProfileTemplateJSON?Token='+credentials.token+'&CID='+credentials?.cId+'&ProfileTemplate='+credentials.formData.template+'&EffectiveDate='+credentials.formData.effectiveDate+'&EndDate='+credentials.formData.endDate+'&Description='+credentials.formData.profileDescription+'&Credit='+credentials.formData.creditCost+'&Active='+credentials.formData.active,{
                method: 'get'
            })
            const data = await response.json()
            return data
        }
        catch(err){
            alert('error')
            return console.log('Add update Team profile JSON err',err)
        }
    }
)

export const AddUpdateTeamDNAProfileTemplateJSON = createAsyncThunk(
    "Admin/AddUpdateTeamDNAProfileTemplateJSON", async(credentials) => {
        try{
            const response = await fetch(Constants.Api.commonUrl+'AddUpdateTeamDNAProfileTemplateJSON?Token='+credentials.token+'&CID='+credentials.cId+'&ProfileTemplate='+credentials.formData.template+'&EffectiveDate='+credentials.formData.effectiveDate+'&EndDate='+credentials.formData.endDate+'&Description='+credentials.formData.profileDescription+'&Credit='+credentials.formData.creditCost+'&Active='+credentials.formData.active,{
                method: 'get'
            })
            const data = await response.json()
            return data
        }
        catch(err){
            return console.log('Add update Team profile JSON err',err)
        }
    }
)

export const CompanyCopySetupJSON = createAsyncThunk(
    "Admin/CompanyCopySetupJSON", async(credentials) => {
        try{
            const response = await fetch(Constants.Api.commonUrl+'CompanyCopySetupJSON?Token='+credentials.token+'&CID='+credentials.cId+'&TemplateCID='+credentials.templateCid, {
                method: 'get'
            })
            const data = await response.json()
            return data
        }
        catch(err){
            return console.log("Company copy err",err)
        }
    }
)

export const CompanySetupJSON = createAsyncThunk(
    "Admin/CompanySetupJSON", async(credentials) => {
        try {
            const response = await fetch(Constants.Api.commonUrl+'CompanySetupJSON?Token='+credentials.token+'&CID='+credentials.cid, {
                method: 'get'
            })
            const data = await response.json();
            return data
        }
        catch(err){
            return console.log('Company setup JSON err', err)
        }
    }
)

export const CompanyClientJSON = createAsyncThunk(
    "Admin/CompanyClientJSON", async(credentials) => {
        try {
            const response = await fetch(Constants.Api.commonUrl+'CompanyClientsJSON?Token='+credentials.token+'&CID='+credentials.cid, {
                method: 'get'
            })
            const data = await response.json()
            return data
        }
        catch (err) {
            return console.log('err', err)
        }
    }
)

export const CompanyEmailTemplateJSON = createAsyncThunk(
    "Admin/CompanyEmailTemplateJSON", async(credentials) => {
        try {
            const response = await fetch(Constants.Api.commonUrl+'CompanyEmailTemplatesJSON?Token='+credentials.token+'&CID='+credentials.cid, {
                method: 'get'
            })
            const data = await response.json()
            return data
        }
        catch (err) {
            return console.log('err' ,err)
        }
    }
)

export const CompanySearchJSON = createAsyncThunk(
    "Admin/CompanySearchJSON", async(credentials) => {
        try {
            const response = await fetch(Constants.Api.commonUrl+'CompanySearchJSON?Token='+credentials.token+'&Name='+credentials.name, {
                method: 'get'
            })
            const data = await response.json()
            return data
        } catch (err) {
            return err
        }
    }
)

export const RightCompanySearchJSON = createAsyncThunk(
    "Admin/RightCompanySearchJSON", async(credentials) => {
        try {
            const response = await fetch(Constants.Api.commonUrl+'CompanySearchJSON?Token='+credentials.token+'&Name='+credentials.name, {
                method: 'get'
            })
            const data = await response.json()
            return data
        } catch (err) {
            return err
        }
    }
)

export const clientListJSON = createAsyncThunk(
    "Admin/clientListJSON", async(credentials) => {
        try {
            // https://services.clarity4d.com/C4DProfile/c4dapi.asmx/SearchPersonCompanyJSON?Token=262e1c2b-c191-48da-823b-fa6299bc8a3e&CID=1&ClientID=%&Criteria=Hieu

            const response = await fetch(Constants.Api.commonUrl+'SearchPersonCompanyJSON?Token='+credentials.userToken+'&CID='+credentials.cId+'&ClientID='+credentials.clientID+'&Criteria='+credentials.clientValue, {
                method: 'get'
            })
            const data = await response.json()
            return data
        } catch (err) {
            return err
        }
    }
)

export const toggleMassTransfer = createAsyncThunk(
    "Admin/toggleMassTransfer", async(credentials) => {
        try {

            const response = await fetch(Constants.Api.commonUrl+'TransferPersonJSON?Token='+credentials.userToken+'&CID='+credentials.leftselectedCid+'&ClientID='+credentials.leftclientID+'&PersonID='+credentials.selectedPersonId+'&ToCID='+credentials.rightselectedCid+'&ToCLientID='+credentials.rightClientID, {
                method: 'get'
            })
            const data = await response.json()
            return data
        } catch (err) {
            return err
        }
    }
)

export const toggleClientTransfer = createAsyncThunk(
    "Admin/toggleClientTransfer", async(credentials) => {
        try {

            const response = await fetch(Constants.Api.commonUrl+'TransferClientJSON?Token='+credentials.userToken+'&PersonID='+credentials.selectedPersonId+'&ToClientID='+credentials.rightClientID, {
                method: 'get'
            })
            const data = await response.json()
            return data
        } catch (err) {
            return err
        }
    }
)

export const CompanySlice = createSlice({
    name: "admin/CompanySlice",
    initialState,
    reducers:{
        setCompany : (state, action) => {
            state['globalCompany'] = action.payload
        }
    },
    extraReducers: {
        [ProfileTemplateJSON.pending] : (state, action) => {
            state.loading = true
        },
        [ProfileTemplateJSON.fulfilled] : (state, action) => {
            state.loading = false
        },
        [ProfileTemplateJSON.rejected] : (state, action) => {
            state.loading = false
        },

        [LicenseAdminJSON.pending] : (state, action) => {
            state.licenseLoading = true
        },
        [LicenseAdminJSON.fulfilled] : (state, action) => {
            state.licenseLoading = false
        },
        [LicenseAdminJSON.rejected] : (state, action) => {
            state.licenseLoading = false
        },

        [TeamDNATemplate.pending] : (state, action) => {
            state.teamDNALoading = true
        },
        [TeamDNATemplate.fulfilled] : (state, action) => {
            state.teamDNALoading = false
        },
        [TeamDNATemplate.rejected] : (state, action) => {
            state.teamDNALoading = false
        },

        [TeamTemplateJSON.pending] : (state, action) => {
            state.teamTemplateLoading = true
        },
        [TeamTemplateJSON.fulfilled] : (state, action) => {
            state.teamTemplateLoading = false
        },
        [TeamTemplateJSON.rejected] : (state, action) => {
            state.teamTemplateLoading = false
        },

        [CompanyCopySetupJSON.pending] : (state, action) => {

        },
        [CompanyCopySetupJSON.fulfilled] : (state, action) => {

        },
        [CompanyCopySetupJSON.rejected] : (state, action) => {

        },

        [CompanyClientJSON.pending] : (state, action) => {
            state.companyClientLoading = true
        },
        [CompanyClientJSON.fulfilled] : (state, action) => {
            state.companyClientLoading = false
        },
        [CompanyClientJSON.rejected] : (state, action) => {
            state.companyClientLoading = false
        },

        [CompanyEmailTemplateJSON.pending] : (state, action) => {
            state.companyEmailTemplateLoading = true
        },
        [CompanyEmailTemplateJSON.fulfilled] : (state, action) => {
            state.companyEmailTemplateLoading = false
        },
        [CompanyEmailTemplateJSON.rejected] : (state, action) => {
            state.companyEmailTemplateLoading = false
        },

        [CompanySetupJSON.pending] : (state, action) => {
            state.userLoader = true

        },
        [CompanySetupJSON.fulfilled] : (state, action) => {
            state.userLoader = false

        },
        [CompanySetupJSON.rejected] : (state, action) => {
            state.userLoader = false
            
        },
        [CompanyBankJSON.pending] : (state, action) => {
            state.loading = true
        },
        [CompanyBankJSON.fulfilled] : (state, action) => {
            state.loading = false
        },
        [CompanyBankJSON.rejected] : (state, action) => {
            state.loading = false
        },
        [CompanyJSON.pending] : (state, action) => {
           state.company=true
        },
        [CompanyJSON.fulfilled] : (state, action) => {
            state.company=false
        },
        [CompanyJSON.rejected] : (state, action) => {
            state.company=false   
        },

        [CompanySearchJSON.pending] : (state, action) => {
            state.companySearchLoader = true
        },
        [CompanySearchJSON.fulfilled] : (state, action) => {
            state.companySearchLoader = false
        },
        [CompanySearchJSON.rejected] : (state, action) => {
            state.companySearchLoader = false
        },

        [RightCompanySearchJSON.pending] : (state, action) => {
            state.rightCompanySearchLoader = true
        },
        [RightCompanySearchJSON.fulfilled] : (state, action) => {
            state.rightCompanySearchLoader = false
        },
        [RightCompanySearchJSON.rejected] : (state, action) => {
            state.rightCompanySearchLoader = false
        },

        [UpdateCompanyJSON.pending] : (state, action) => {
            state.updateCompanyLoader = true
        },
        [UpdateCompanyJSON.fulfilled] : (state, action) => {
            state.updateCompanyLoader = false
        },
        [UpdateCompanyJSON.rejected] : (state, action) => {
            state.updateCompanyLoader = false
        },

        [toggleMassTransfer.pending] : (state, action) => {
            state.rightSideGridLoader = true
        },
        [toggleMassTransfer.fulfilled] : (state, action) => {
            state.rightSideGridLoader = false
        },
        [toggleMassTransfer.rejected] : (state, action) => {
            state.rightSideGridLoader = false
        }
    }
})


export const {setCompany} = CompanySlice.actions
export default CompanySlice.reducer