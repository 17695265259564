import React, { useState, useEffect } from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Box, Typography } from '@mui/material';

const CmnBackdrop = React.memo((props) => {
    const [open, setOpen] = useState(props.backdrop);
    const [count, setCount] = useState(0)
    const [usePercentage, setUsePercentage] = useState(true)
    const [total, setTotal] = useState(0)

    useEffect(() => {
        if (props['value'] !== undefined) {
            setCount(props['value'])
        }
    }, [props.value])

    useEffect(() => {
        if (props.usePercentage !== undefined) {
            setUsePercentage(props.usePercentage)
        }
    }, [props.usePercentage])

    useEffect(() => {
        if (props.total !== undefined) {
            setTotal(props.total)
        }
    }, [props.total])

    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <CircularProgress color="inherit" size={60} />
                {props.value !== undefined && <Box
                    sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: 'absolute',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Typography variant="caption" component="div" color="#ffffff" fontSize={'1.1rem'}>
                        {usePercentage ? `${Math.round(count)}%` : `${count} / ${total}` }
                    </Typography>
                    {usePercentage && <Typography variant='caption' component={'div'} color={'#ffffff'} fontSize={'1.1rem'}>
                        {Math.round(count) === 100 && 'Please wait some time...'}
                    </Typography>}
                </Box>}
            </Backdrop>
        </>
    )
})

export default CmnBackdrop