import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Constants from "../../constants";

//Profile status function
export const ProfileStatusFunction = createAsyncThunk(
    "dashboard/ProfileStatusFunction",async (credentials) => {
        try{
            const response = await fetch(Constants.Api.commonUrl+'ProfileStatusJSON?Token='+credentials.token,{
                method : 'GET'
            })
            const data = await response.json()
            return data
        }
        catch(err){
            return err
        }
    }
)

// credit invoice function
export const CreditInvoiceStatusFunction = createAsyncThunk(
    "dashboard/CreditInvoiceStatusFunction", async(credentials) => {
        try{
            const response = await fetch(Constants.Api.commonUrl+'CreditInvoiceStatusJSON?Token='+credentials.token, {
                method : 'GET'
            })
            const data = await response.json()
            return data
        }
        catch(err){
            return err
        }
    }
)

//dashboard function
export const DashboardFunction = createAsyncThunk(
    "dashboard/DashboardFunction",async(credentials) => {
        try{
            const response = await fetch(Constants.Api.commonUrl+'DashboardJSON?Token='+credentials.token+'&DashboardID='+Constants.dashboardJson.dashboardId+'&Period='+Constants.dashboardJson.period,{
                method : 'GET'
            })
            const data = await response.json()
            return data
        }
        catch(err){
            return err
        }
    }
)

//worket function
export const WorkletFunction = createAsyncThunk(
    "dashboard/WorkletFunction", async(credentials) => {
        try{
            const response = await fetch(Constants.Api.commonUrl+'WorkletJSON?Token='+credentials.token+'&WorkletID='+credentials.workletId+'&Period='+credentials.period,{
                method: 'GET'
            })
            const data = await response.json()
            return data
        }
        catch(err){
            return err
        }
    }
)

const initialState = {
    loader : false,
    workletLoader : false
}

const DashboardSlice = createSlice({
    name : 'dshboard',
    initialState,
    reducers : {

    },
    extraReducers : {
        [DashboardFunction.pending] : (state, action) => {
            state.loader = true
        },
        [DashboardFunction.fulfilled] : (state, action) => {
            state.loader = false
        },
        [DashboardFunction.rejected] : (state, action) => {
            state.loader = false
        },
        [ProfileStatusFunction.pending] : (state, action) => {

        },
        [ProfileStatusFunction.fulfilled] : (state, action) => {
        
        },
        [ProfileStatusFunction.rejected] : (state, action) => {

        },
        [CreditInvoiceStatusFunction.pending] : (state, action) => {

        },
        [CreditInvoiceStatusFunction.fulfilled] : (state, action) => {

        },
        [CreditInvoiceStatusFunction.rejected] : (state, action) => {
            
        },

        [WorkletFunction.pending] : (state, action) => {
            state.workletLoader = true
        },
        [WorkletFunction.fulfilled] : (state, action) => {
            state.workletLoader = false
        },
        [WorkletFunction.rejected] : (state, action) => {

        }
    }
})

export const {} = DashboardSlice.actions
export default DashboardSlice.reducer 