import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Constants from "../../constants";

const initialState = {
    clientList: [],
    clientId: '',
    showLoader: false,
    tableLoader: false,
}

export const PublishedTeamDNAStatusJSON = createAsyncThunk(
    "profile/PublishedTeamDNAStatusJSON", async (credentials) => {
        try{ 
            const response = await fetch(Constants.Api.commonUrl+'PublishedTeamDNAStatusJSON?Token='+credentials.token+'&TeamId='+credentials.teamId, {
                method: 'get'
            })
            const data = await response.json()
            return data
        }
        catch(err) {
            return console.log('Published Team DNA Status JSON', err)
        }
    }
)

export const PublishTeamDNAReportJSON = createAsyncThunk(
    "profile/PublishTeamDNAReportJSON", async (credentials) => {
        try{
            const response = await fetch(Constants.Api.commonUrl+'PublishTeamDNAReportJSON?Token='+credentials.token+'&TeamID='+credentials.teamId+'&Language='+credentials.language+'&Profile='+credentials.profile, {
                method: 'get'
            })
            const data = await response.json()
            return data
        }
        catch(err) {
            return console.log('Published Team DNA error', err)
        }
    }
)

export const OTSTeamDNATeamsJSONFunction = createAsyncThunk(
    "profile/profileStatus", async (credentials) => {
        try {
            const response = await fetch(Constants.Api.commonUrl + 'TeamsJSON?Token=' + credentials.token +'&Criteria=' +credentials.Criteria+ {
                method: 'GET'
            })
            const data = await response.json()
            return data
        }
        catch (err) {
            return console.log('profile status function', err)
        }
    }
)

export const OTSTeamDNATeamplateJSONFunction = createAsyncThunk(
   "profile/profileStatus", async (credentials) => {
       try {
           const response = await fetch(Constants.Api.commonUrl+'TemplatesJSON?Token='+credentials.token+'&Criteria='+credentials.Criteria+ {
               method: 'GET'
           })
           const data = await response.json()
           return data
       }
       catch (err) {
           return console.log('profile status function', err)
       }
   }
)

export const OTSTeamDNATeamStatusJSONFunction = createAsyncThunk(
   "profile/profileStatus", async (credentials) => {
       try {
           const response = await fetch(Constants.Api.commonUrl +'TeamStatusJSON?Token='+credentials.token+'&TeamID='+credentials.TeamID+'&PersonID='+credentials.PersonID,{
               method: 'GET'
           })
           const data = await response.json()
           return data
       }
       catch (err) {
           return console.log('profile status function', err)
       }
   }
)


export const OTSTeamDNATeamReportExitJSONFunction = createAsyncThunk(
   "profile/profileStatus", async (credentials) => { 
       try {
           const response = await fetch(Constants.Api.commonUrl+'TeamReportExistsJSON?Token='+credentials.token+'&TeamID='+credentials.TeamID,{
               method: 'GET'
           })
           const data = await response.json()
           return data
       }
       catch (err) {
           return console.log('profile status function', err)
       }
   }
)

export const OTSTeamDNAStatusJSON = createAsyncThunk(
    "issueSurvey/TeamDNAStatusJSON", async (credentials) => {
        try{
            const response = await fetch(Constants.Api.commonUrl + 'TeamDNAStatusJSON?Token='+credentials.token+'&TeamID='+credentials.teamId+'&PersonID='+credentials.personId, {
                method: 'get'
            })
            const data = await response.json()
            return data
        }
        catch(err) {
            return console.log("TeamDNAStatusJSON error", err)
        }
    }
)


const TeamDNASurveySlice = createSlice({
    name: 'profileStatus',
    initialState,
    reducers: {
        setClientId: (state, action) => {
            state.clientId = action.payload
        },
        reset: () => initialState
    },
    extraReducers: {
        [OTSTeamDNATeamsJSONFunction.pending]: (state, action) => {
            state.showLoader = true
        },
        [OTSTeamDNATeamsJSONFunction.fulfilled]: (state, action) => {
            state.showLoader = false
        },
        [OTSTeamDNATeamsJSONFunction.rejected]: (state, action) => {
            state.showLoader = false
        },

        [OTSTeamDNATeamplateJSONFunction.pending]: (state, action) => {
         state.showLoader = true
        },
        [OTSTeamDNATeamplateJSONFunction.fulfilled]: (state, action) => {
         state.showLoader = false
        },
        [OTSTeamDNATeamplateJSONFunction.rejected]: (state, action) => {
         state.showLoader = false
        },

        [OTSTeamDNATeamStatusJSONFunction.pending]: (state, action) => {
         state.showLoader = true
        },
        [OTSTeamDNATeamStatusJSONFunction.fulfilled]: (state, action) => {
         state.showLoader = false
        },
        [OTSTeamDNATeamStatusJSONFunction.rejected]: (state, action) => {
         state.showLoader = false
        },

        [OTSTeamDNATeamReportExitJSONFunction.pending]: (state, action) => {
         state.showLoader = true
        },
        [OTSTeamDNATeamReportExitJSONFunction.fulfilled]: (state, action) => {
         state.showLoader = false
        },
        [OTSTeamDNATeamReportExitJSONFunction.rejected]: (state, action) => {
         state.showLoader = false
        },

        [OTSTeamDNAStatusJSON.pending] : (state, action) => {
            state.tableLoader = true
        },
        [OTSTeamDNAStatusJSON.fulfilled] : (state, action) => {
            state.tableLoader = false
        },
        [OTSTeamDNAStatusJSON.rejected] : (state, action) => {
            state.tableLoader = false
        },

        [PublishedTeamDNAStatusJSON.pending] : (state, action) => {

        },
        [PublishedTeamDNAStatusJSON.fulfilled] : (state, action) => {

        },
        [PublishedTeamDNAStatusJSON.rejected] : (state, action) => {

        },

        [PublishTeamDNAReportJSON.pending] : (state, action) => {
            state.showLoader = true
        },
        [PublishTeamDNAReportJSON.fulfilled] : (state, action) => {
            state.showLoader = false
        },
        [PublishTeamDNAReportJSON.rejected] : (state, action) => {
            state.showLoader = false
        }
     
    }
})

export const { setClientId, reset } = TeamDNASurveySlice.actions
export default TeamDNASurveySlice.reducer