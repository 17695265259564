import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Constants from '../../constants'
const initialState = {
    userName: '',
    password:'',
    accountJson:{},
    isLoading:false,
    drawerAction:false,
    accessRights: '',
    mfaValidateLoading: false,
};


export const loginFunction = createAsyncThunk(
    "login/loginFunction", async (credentials) => {
      sessionStorage.setItem('usernameAdmin',credentials.name)
      try {
        const response = await fetch(Constants.Api.commonUrl+'AuthenticateJSON?userName='+credentials.name+'&Password='+credentials.values.password, {
          method: 'GET'
        });
        const data = await response.json()
        return data;
      } catch (err) {
        return console.log(err);
      }
    }
  );

  export const AccountJSONFn = createAsyncThunk(
    "login/AccountJSONFn", async (userToken) => {
      try {
        const response = await fetch(Constants.Api.commonUrl+'AccountJSON?Token='+userToken, {
          method: 'GET'
        });
        const data = await response.json()
        return data;
      } catch (err) {
        return console.log(err);
      }
    }
  );

  export const HistoryJSON = createAsyncThunk(
    "login/HistoryJSON", async(credentials) => {
      try{
        const response = await fetch(Constants.Api.commonUrl+'HistoryJSON?Token='+credentials.token,{
          method : 'get'
        })
        const data = await response.json()
        return data
      }
      catch(err){
        return console.log(err)
      }
    }
  )

  export const SetSessionJSON = createAsyncThunk(
    "login/SetSessionJSON", async(credentials) => {
      try{
        const response = await fetch(Constants.Api.commonUrl+'SetSessionJSON?Token='+credentials.token+'&Name='+credentials.name+'&Value='+credentials.value,{
          method : 'get'
        })
        const data = await response.json()
        return data
      }
      catch(err){
        return console.log(err)
      }
    }
  )

  export const GetSessionJSON = createAsyncThunk(
    "login/GetSessionJSON", async(credentials) => {
      try{
        const response = await fetch(Constants.Api.commonUrl+"GetSessionJSON?Token="+credentials.token+"&Name="+credentials.name,{
          method : 'get'
        })
        const data = await response.json()
        return data
      }
      catch(err){
        return console.log(err)
      }
    }
  )

  export const TranslateJSON = createAsyncThunk(
    'login/TranslateJSON', async(credentials) => {
      try{
        const response = await fetch(Constants.Api.commonUrl+'TranslateJSON?Language='+credentials.lang+'&Statement='+credentials.statement,{
          method : 'get'
        })
        const data = await response.json()
        return data
      }
      catch(err){
        return console.log(err)
      }
    }
  )

  export const EnvironmentJSON = createAsyncThunk(
    'login/EnvironmentJSON', async() => {
      try{
        const response = await fetch(Constants.Api.commonUrl+'Environment',{
          method: 'get'
        })
        const data = await response.json()
        return data
      }
      catch(err){
        return console.log(err)
      }
    }
  )

  export const ValidateMfaCodeJSON = createAsyncThunk(
    'login/ValidateMfaCodeJSON', async (credentials) => {
      try {
        const response = await fetch(Constants.Api.commonUrl+'CheckVerificationJSON?Token='+credentials.token, {
          method: 'get'
        })
        const data = await response.json()
        return data
      } catch (err) {
        return err;
      }
    }
  )

  export const GetVerificationCodeJSON = createAsyncThunk(
    'login/GetVerificationCodeJSON', async (credentials) => {
      try {
        const response = await fetch(Constants.Api.commonUrl+'GetVerificationJSON?Token='+credentials.token+'&Language='+credentials.language, {
          method: 'get'
        })
        const data = await response.json();
        return data
      } catch (err) {
        return err;
      }
    }
  )

const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        setUserName: (state, action) => {
            state.userName = action.payload
        },
        setUserPassword: (state, action) => {
          state.password = action.payload
        },
        setDrawerAction: (state, action) => {
          state.drawerAction = action.payload
        },
        setIsLoading: (state, action) => {
          state.isLoading = action.payload
        },
    },
    extraReducers: {
      [loginFunction.pending] : (state, action) => {
        state.isLoading=true
      },
      [loginFunction.fulfilled] : (state, action) => {
        state.isLoading=false
        state.token = action.payload
      },
      [loginFunction.rejected] : (state, action) => {
      },
      [AccountJSONFn.pending] : (state, action) => {
      },
      [AccountJSONFn.fulfilled] : (state, action) => {
        state.accountJson = action.payload.Result
        sessionStorage.setItem('username',state.accountJson['Account']['Name'])
        sessionStorage.setItem('companyName',state.accountJson['Account']['Company'])
        state.accessRights = action.payload.Result.Account.Security_Group
      },
      [AccountJSONFn.rejected] : (state, action) => {
      },

      [SetSessionJSON.pending] : (state, action) => {

      },
      [SetSessionJSON.fulfilled] : (state, action) => {

      },
      [SetSessionJSON.pending] : (state, action) => {

      },

      [GetSessionJSON.pending] : (state, action) => {

      },
      [GetSessionJSON.fulfilled] : (state, action) => {

      },
      [GetSessionJSON.rejected] : (state, action) => {
        
      },

      [TranslateJSON.pending] : (state, action) => {

      },
      [TranslateJSON.fulfilled] : (state, action) => {
        
      },
      [TranslateJSON.rejected] : (state, action) => {
        
      },

      [EnvironmentJSON.pending] : (state, action) => {

      },
      [EnvironmentJSON.fulfilled] : (state, action) => {

      },
      [EnvironmentJSON.rejected] : (state, action) => {
        
      },

      [ValidateMfaCodeJSON.pending]: (state, action) => {
        state.mfaValidateLoading = true;
      },
      [ValidateMfaCodeJSON.fulfilled]: (state, action) => {
        state.mfaValidateLoading = false;
      },
      [ValidateMfaCodeJSON.rejected]: (state, action) => {
        state.mfaValidateLoading = false;
      },

      [GetVerificationCodeJSON.pending]: (state, action) => {

      },
      [GetVerificationCodeJSON.fulfilled]: (state, action) => {

      },
      [GetVerificationCodeJSON.rejected]: (state, action) => {
        
      }
    }
})


export const { setUserName, setUserPassword,setDrawerAction, setIsLoading } = loginSlice.actions;

export default loginSlice.reducer;