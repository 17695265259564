import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Constants from "../../constants";

const initialState = {
    languageListLoader: false,
    languageCollection: {},
}

export const SystemLanguageListJSON = createAsyncThunk(
    'system/SystemLanguageListJSON', async(credentials) => {
        try {
            const response = await fetch(Constants.Api.commonUrl+'LanguageListJSON?Token='+credentials.token+'&LANGUAGE='+credentials.language+'&CODE='+credentials.code, {
                method: 'get'
            })
            const data = await response.json();
            return data
        } catch (err) {

        }
    }
)

export const SystemAddUpdateLanguageJSON = createAsyncThunk(
    'system/SystemAddUpdateLanguageJSON', async(credentials) => {
        try {
            const response = await fetch(Constants.Api.commonUrl+'AddUpdateLanguageJSON?Token='+credentials.token+'&LANGUAGE='+credentials.language+'&CODE='+credentials.code+'&TEXTSTRING='+credentials.textString, {
                method: 'get',
            })
            const data = await response.json();
            return data;
        } catch (err) {

        }
    }
)

export const AdminSystemSlice = createSlice({
    name: 'AdminSystemSlice',
    initialState,
    reducers: {
        setLanguageCollection: (state, action) => {
            window.lt = {...action.payload};
            state.languageCollection = {...action.payload}
        },
    },
    extraReducers: {
        [SystemLanguageListJSON.pending]: (state, action) => {
            state.languageListLoader = true
        },
        [SystemLanguageListJSON.fulfilled]: (state, action) => {
            state.languageListLoader = false
        },
        [SystemLanguageListJSON.rejected]: (state, action) => {
            state.languageListLoader = false
        },

        [SystemAddUpdateLanguageJSON.pending]: (state, action) => {

        },
        [SystemAddUpdateLanguageJSON.fulfilled]: (state, action) => {

        },
        [SystemAddUpdateLanguageJSON.rejected]: (state, action) => {
            
        }
    }
})
export const { setLanguageCollection } = AdminSystemSlice.actions
export default AdminSystemSlice.reducer