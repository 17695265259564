import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Constants from "../../constants";

const initialState = {
    clientList: [],
    clientId: '',
    showLoader: false
}

export const ProfileStatusFunction = createAsyncThunk(
    "profile/profileStatus", async (credentials) => {
        try {
            const response = await fetch(Constants.Api.commonUrl + 'ProfileStatusDetailJSON?Token=' + credentials.token + '&Client=' + credentials.client + '&Status=' + credentials.status + '&StatusPeriod=' + credentials.statusPeriod, {
                method: 'GET'
            })
            const data = await response.json()
            return data
        }
        catch (err) {
            return console.log('profile status function', err)
        }
    }
)

export const FeedbackStatusFunction = createAsyncThunk(
    'profile/feedbackStatus', async (credentials) => {
        try {
            const response = await fetch(Constants.Api.commonUrl + 'FeedbackStatusDetailJSON?Token=' + credentials.token + '&Status=' + credentials.status + '&StatusPeriod=' + credentials.statusPeriod + '&Client=' + credentials.client, {
                method: 'GET'
            })
            const data = await response.json()
            return data
        }
        catch (err) {
            return console.log('feedback status function', err)
        }
    }
)

export const SentRecievedFunction = createAsyncThunk(
    'profile/sentRecieved', async (credentials) => {
        try {
            const response = await fetch(Constants.Api.commonUrl + 'SentReceivedDetailJSON?Token=' + credentials.token + '&StatusPeriod=' + credentials.statusPeriod + '&Client=' + credentials.client, {
                method: 'GET'
            })
            const data = await response.json()
            return data
        }
        catch (err) {
            return console.log('sent and received status function', err)
        }
    }
)

export const ClientJSONFunction = createAsyncThunk(
    "profile/clientJSON", async (credentials) => {
        try {
            const response = await fetch(Constants.Api.commonUrl + 'ClientsJSON?Token=' + credentials.token + '&Criteria=' + credentials.criteria, {
                method: 'GET'
            })
            const data = await response.json()
            return data
        }
        catch (err) {
            return console.log('client json err', err)
        }
    }
)

export const HelpScreenshotJSONFunction = createAsyncThunk(
    "profile/screenshotJSON", async (credentials) => {
        try {
            const response = await fetch(Constants.Api.commonUrl + 'ScreenHelpJSON?Language=' + credentials.language + '&Token=' + credentials.token + '&Screen=' + credentials.screen, {
                method: 'GET'
            })
            const data = await response.json()
            return data
        }
        catch (err) {
            return console.log('screenshotJSON error', err)
        }
    }
)

const profileStatusSlice = createSlice({
    name: 'profileStatus',
    initialState,
    reducers: {
        setClientId: (state, action) => {
            state.clientId = action.payload
        },
        reset: () => initialState
    },
    extraReducers: {
        [ProfileStatusFunction.pending]: (state, action) => {
            state.showLoader = true
        },
        [ProfileStatusFunction.fulfilled]: (state, action) => {
            state.showLoader = false
        },
        [ProfileStatusFunction.rejected]: (state, action) => {
            state.showLoader = false
        },

        [FeedbackStatusFunction.pending]: (state, action) => {
            state.showLoader = true
        },
        [FeedbackStatusFunction.fulfilled]: (state, action) => {
            state.showLoader = false
        },
        [FeedbackStatusFunction.rejected]: (state, action) => {
            state.showLoader = false
        },

        [SentRecievedFunction.pending]: (state, action) => {
            state.showLoader = true
        },
        [SentRecievedFunction.fulfilled]: (state, action) => {
            state.showLoader = false
        },
        [SentRecievedFunction.rejected]: (state, action) => {
            state.showLoader = false
        },

        [ClientJSONFunction.pending]: (state, action) => {
            state.showLoader = true
        },
        [ClientJSONFunction.fulfilled]: (state, action) => {
            if(Array.isArray(action['payload']['Result']['Client'])){
                state.clientList = action['payload']['Result']['Client'];
            }
            else if(!Array.isArray(action['payload']['Result']['Client'])){
                state.clientList = [action['payload']['Result']['Client']];
            }
            else{
                state.clientList = []
            }
            state.showLoader = false
        },
        [ClientJSONFunction.rejected]: (state, action) => {
            state.showLoader = false
        },

        [HelpScreenshotJSONFunction.pending]: (state, action) => {

        },
        [HelpScreenshotJSONFunction.fulfilled]: (state, action) => {

        },
        [HelpScreenshotJSONFunction.rejected]: (state, action) => {

        }
    }
})

export const { setClientId, reset } = profileStatusSlice.actions
export default profileStatusSlice.reducer