import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Constants from '../../constants'
const initialState = {
    isLoading:true,
    isCreditLoading:false,
    isInvoiceLoading:false,
    orderCredits:{},
    licenseLoader: false,
};


export const getListofClientsServer = createAsyncThunk(
    "invoiceAndCredits/getListofClientsServer", async (token) => {
      try {
        const response = await fetch(Constants.Api.commonUrl+'ClientsJSON?Token='+token+'&Criteria=%', {
          method: 'GET'
        });
        const data = await response.json()
        return data;
      } catch (err) {
        return console.log(err);
      }
    }
  );
  export const getListofLicenseServer = createAsyncThunk(
    "invoiceAndCredits/getListofLicenseServer", async (token) => {
      try {
        const response = await fetch(Constants.Api.commonUrl+'LicenseJSON?Token='+token+'&License=%', {
          method: 'GET'
        });
        const data = await response.json()
        return data;
      } catch (err) {
        return console.log(err);
      }
    }
  );
  
  export const orderCreditJson = createAsyncThunk(
    "invoiceAndCredits/orderCreditJson", async (params) => {
      try {
        const response = await fetch(Constants.Api.commonUrl+'OrderCreditsJSON?Token='+params.token+'&Credit='+params.credit, {
          method: 'GET'
        });
        const data = await response.json()
        return data;
      } catch (err) {
        return console.log(err);
      }
    }
  );
  export const creditBalanceJsonServer = createAsyncThunk(
    "invoiceAndCredits/creditBalanceJsonServer", async (params) => {
      try {
        const response = await fetch(Constants.Api.commonUrl+'CreditBalanceJSON?Token='+params.token+'&License='+params.license+'&Client='+params.client, {
          method: 'GET'
        });
        const data = await response.json()
        return data;
      } catch (err) {
        return console.log(err);
      }
    }
  );

  export const listofMaterialsServer = createAsyncThunk(
    "invoiceAndCredits/listofMaterialsServer", async (token) => {
      try {
        const response = await fetch(Constants.Api.commonUrl+'MaterialsJSON?Token='+token, {
          method: 'GET'
        });
        const data = await response.json()
        return data;
      } catch (err) {
        return console.log(err);
      }
    }
  );
  export const OrderMaterialsJSONFN = createAsyncThunk(
    "invoiceAndCredits/OrderMaterialsJSONFN", async (token) => {
      try {
        const response = await fetch(Constants.Api.commonUrl+'OrderMaterialsJSON?Token='+token, {
          method: 'GET'
        });
        const data = await response.json()
        return data;
      } catch (err) {
        return console.log(err);
      }
    }
  );

  export const AddMaterialsJSONServer = createAsyncThunk(
    "invoiceAndCredits/AddMaterialsJSONServer", async (params) => {
      try {
        const response = await fetch(Constants.Api.commonUrl+'AddMaterialsJSON?Token='+params?.userToken+'&MaterialID='+params?.materialId+'&Quantity='+params?.qty, {
          method: 'GET'
        });
        const data = await response.json()
        return data;
      } catch (err) {
        return console.log(err);
      }
    }
  );

  export const InvoiceList = createAsyncThunk(
    "invoiceAndCredits/InvoiceList", async (token) => {
      try {
        const response = await fetch(Constants.Api.commonUrl+'InvoiceJSON?Token='+token+'&Status=A', {
          method: 'GET'
        });
        const data = await response.json()
        return data;
      } catch (err) {
        return console.log(err);
      }
    }
  );

  export const showInvoicePdfFn = createAsyncThunk(
    "invoiceAndCredits/showInvoicePdfFn", async (params) => {
      try {
        const response = await fetch(Constants.Api.commonUrl+'GetInvoiceReportJSON?Token='+params?.userToken+'&InvoiceNo='+params?.InvoiceId, {
          method: 'GET'
        });
        const data = await response.json()
        return data;
      } catch (err) {
        return console.log(err);
      }
    }
  );

  export const payInvoiceCreditServer = createAsyncThunk(
    "invoiceAndCredits/PayInvoiceJSON", async (params) => {
      try {
        const response = await fetch(Constants.Api.commonUrl+'PayInvoiceJSON?Token='+params?.userToken+'&InvoiceNo='+params?.invoiceId, {
          method: 'GET'
        });
        const data = await response.json()
        return data;
      } catch (err) {
        return console.log(err);
      }
    }
  );
  
  export const payInvoiceCommitJSON = createAsyncThunk(
    "invoiceAndCredits/payInvoiceCommitJSON", async (credentials) => {
      try{
        const response = await fetch(Constants.Api.commonUrl+'PayInvoiceCommitJSON?InvoiceNo='+credentials.invoiceNo,{
          method : 'GET'
        })
        const data = await response.json()
        return data
      }
      catch(err){
        return console.log(err)
      }
    }
  )
  

  
const invoiceAndCredits = createSlice({
    name: 'invoiceAndCredits',
    initialState,
    reducers: {
        // setUserName: (state, action) => {
        //     state.userName = action.payload
        // },
        // setUserPassword: (state, action) => {
        //   state.password = action.payload
        // }
    },
    extraReducers: {
      [getListofClientsServer.pending] : (state, action) => {
        state.isLoading=true
      },
      [getListofClientsServer.fulfilled] : (state, action) => {
        state.isLoading=false
        // state.emailTemplatesJson=action.payload
      },
      [getListofClientsServer.rejected] : (state, action) => {
        
      },


      [orderCreditJson.pending] : (state, action) => {
        
      },
      [orderCreditJson.fulfilled] : (state, action) => {
       
       
        state.orderCredits=action?.payload?.Result
      },
      [orderCreditJson.rejected] : (state, action) => {
        
      },

      [listofMaterialsServer.pending] : (state, action) => {
        state.isLoading=true
      },
      [listofMaterialsServer.fulfilled] : (state, action) => {
        state.isLoading=false
       
      },
      [listofMaterialsServer.rejected] : (state, action) => {
        state.isLoading=false
      },

      [creditBalanceJsonServer.pending] : (state, action) => {
        state.isCreditLoading=true
      },
      [creditBalanceJsonServer.fulfilled] : (state, action) => {
        state.isCreditLoading=false
       
      },
      [creditBalanceJsonServer.rejected] : (state, action) => {
        state.isCreditLoading=false
      },

      [InvoiceList.pending] : (state, action) => {
        state.isInvoiceLoading=true
      },
      [InvoiceList.fulfilled] : (state, action) => {
        state.isInvoiceLoading=false
       
      },
      [InvoiceList.rejected] : (state, action) => {
        state.isInvoiceLoading=false
      },

      [showInvoicePdfFn.pending] : (state, action) => {
        state.isInvoiceLoading=true
      },
      [showInvoicePdfFn.fulfilled] : (state, action) => {
        state.isInvoiceLoading=false
       
      },
      [showInvoicePdfFn.rejected] : (state, action) => {
        state.isInvoiceLoading=false
      },

      [payInvoiceCommitJSON.pending] : (state, action) => {

      },
      [payInvoiceCommitJSON.fulfilled] : (state, action) => {

      },
      [payInvoiceCommitJSON.rejected] : (state, action) => {

      },

      [getListofLicenseServer.pending] : (state, action) => {
        state.licenseLoader = true;
      },
      [getListofLicenseServer.fulfilled] : (state, action) => {
        state.licenseLoader = false
      },
      [getListofLicenseServer.rejected] : (state, action) => {
        state.licenseLoader = false
      }

     
    }
})


export const {  } = invoiceAndCredits.actions;

export default invoiceAndCredits.reducer;