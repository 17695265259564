import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Constants from "../../constants";

const initialState = {
    globalCompany: {}
}

export const AdminResourceJSON = createAsyncThunk(
    'admin/ResourceAdminJSON', async(credentials) => {
        try{
            const response = await fetch(Constants.Api.commonUrl+'ResourceAdminJSON?Token='+credentials.token+'&ResourceID='+credentials.resourceID+'&DocumentID='+credentials.documentID,{
                method: 'get'
            })
            const data = await response.json()
            return data
        }
        catch(err){
            return console.log("company json err",err)
        }
    }
)


export const UpdateResourceDocumentDetailsJSON = createAsyncThunk(
    'admin/ResourceAdminJSON', async(credentials) => {
        try{
            const response = await fetch(Constants.Api.commonUrl+'UpdateResourceJSON?Token='+credentials.token+'&DocumentID='+credentials.formData.documentID+'&DocumentType='+credentials.formData.documentType+'&Name='+credentials.formData.name+'&Active='+credentials.formData.Active+'&Filename='+credentials.formData.fileName+'&Description='+credentials.formData.description+'&Sort='+credentials.formData.Order+'&Category='+credentials.formData.CategoriesOne+'&Category2='+credentials.formData.CategoriesTwo+'&Category3='+credentials.formData.CategoriesThree,{
                method : 'get' 
            })
            const data = await response.json()
            return data
        }
        catch(err){
            return console.log("company json err",err)
        }
    }
)


export const addeResourceDocumentDetailsJSON = createAsyncThunk(
    'admin/ResourceAdminJSON', async(credentials) => {
        try{
            const response = await fetch(Constants.Api.commonUrl+'AddResourceJSON?Token='+credentials.token+'&DocumentType='+credentials.formData.documentType+'&Name='+credentials.formData.name+'&Active='+credentials.formData.Active+'&Filename='+credentials.formData.fileName+'&Description='+credentials.formData.description+'&Sort='+credentials.formData.Order+'&Category='+credentials.formData.CategoriesOne+'&Category2='+credentials.formData.CategoriesTwo+'&Category3='+credentials.formData.CategoriesThree,{
                method : 'get' 
            })
            const data = await response.json()
            return data
        }
        catch(err){
            return console.log("company json err",err)
        }
    }
)

export const AdminDocumentslice = createSlice({
    name: "AdminDocumentslice",
    initialState,
    reducers:{
        setCompany : (state, action) => {
            state['globalCompany'] = action.payload
        }
    },
    
    extraReducers: {
        [UpdateResourceDocumentDetailsJSON.pending] : (state, action) => {
            state.loading = true;

        },
        [UpdateResourceDocumentDetailsJSON.fulfilled] : (state, action) => {
           
            state.loading = true;
        },
        [UpdateResourceDocumentDetailsJSON.rejected] : (state, action) => {
            state.loading = true;
            
        },
        [addeResourceDocumentDetailsJSON.pending] : (state, action) => {
            state.loading = true;

        },
        [addeResourceDocumentDetailsJSON.fulfilled] : (state, action) => {
           
            state.loading = true;
        },
        [addeResourceDocumentDetailsJSON.rejected] : (state, action) => {
            state.loading = true;
            
        },
    }
})

export const {setCompany} = AdminDocumentslice.actions
export default AdminDocumentslice.reducer