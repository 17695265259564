import React from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button';
import '../common-content-btn/BtnContent.css';

const BtnIconContent = React.memo((props) => {
    return(
        <>
                <Button disabled={props?.disabled} endIcon={props.startIcon} startIcon={props.startIcon} className = 'text-capitalize'  variant="contained" color = {props.color} onClick = {props.click}  size={props.size}>
                    {props.children}
                </Button>
        </>
    )
})

BtnIconContent.propTypes = {
    startIcon : PropTypes.node,
    color : PropTypes.string,
    click : PropTypes.func,
    size : PropTypes.string,
    children : PropTypes.any
}

export default BtnIconContent