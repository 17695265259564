import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Constants from '../../constants'
const initialState = {

  showLoader: true,
  teamJson: {},
  searchValue:'',
  checkValue:false
};


export const globalSearchServer = createAsyncThunk(
  "teams/globalSearchServer", async (params) => {
    try {
      const response = await fetch(Constants.Api.commonUrl + 'SearchGlobalJSON?Token=' + params.token + '&Type=' + params.type + '&Criteria=' + params.Criteria, {
        method: 'GET'
      });
      const data = await response.json()
      return data;
    } catch (err) {
      return console.log(err);
    }
  }
);





const globalSearchSlice = createSlice({
  name: 'globalSearch',
  initialState,
  reducers: {
    changeSearchValue: (state, action) => {
      state.searchValue = action.payload
    },
    changeCheckValue: (state, action) =>{
      console.log('///////////',action.payload)
      state.changeCheckValue = action.payload
    }
  },
  extraReducers: {
    [globalSearchServer.pending]: (state, action) => {
      state.showLoader=true
    },
    [globalSearchServer.fulfilled]: (state, action) => {
      state.showLoader = false
    },
    [globalSearchServer.rejected]: (state, action) => {
      state.showLoader = false
    },



  }
})


export const { changeSearchValue,changeCheckValue } = globalSearchSlice.actions;

export default globalSearchSlice.reducer;