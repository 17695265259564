import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Constants from "../../constants";

const initialState = {
    clientList : []
}

export const ResourceJsonFunction = createAsyncThunk(
    'personSearch/reportsSearchSlice',async(credentials) => {
        try{
            const response = await fetch(Constants.Api.commonUrl+'ResourceJSON?Token='+credentials.token,{
                
                method : 'GET'
            })
            const data = await response.json()
            return data
        }
        catch(err){
            return console.log('ResourceJSON',err)
        }
    }
)




export const reportSearchSlice = createSlice({
    name : 'reportSearchSlice',
    initialState,
    reducers : {
        // triggerRefresh : (state, action) => {
        // }
    },
    extraReducers : {
        [ResourceJsonFunction.pending] : (state, action) => {

        },
        [ResourceJsonFunction.fulfilled] : (state, action) => {
            state.clientList = action['payload']['Result']['Client'];
        },
        [ResourceJsonFunction.rejected] : (state, action) => {
            
        }
    }
})

export const {} = reportSearchSlice.actions
export default reportSearchSlice.reducer


