import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Constants from '../../constants'
const initialState = {
   
    isLoading:true,
    emailTemplatesJson:{}
};


export const getEmailTemplates = createAsyncThunk(
    "emailTemplate/getEmailTemplates", async (token) => {
      try {
        const response = await fetch(Constants.Api.commonUrl+'TemplatesJSON?Token='+token+'&Criteria=%', {
          method: 'GET'
        });
        const data = await response.json()
        return data;
      } catch (err) {
        return console.log(err);
      }
    }
  );
export const updateEmailTemplate = createAsyncThunk(
  "emailTemplate/updateEmailTemplate", async (params) => {
    console.log('updateEmailTemplate',params)
    try {
      const response = await fetch(Constants.Api.commonUrl+'UpdateTemplateJSON?Token='+params.userToken+'&Name='+`${params.templateName}`+'&Subject='+params.subject+'&Active='+params.status+'&Message='+params.messageresult, {
        method: 'GET'
      });
      const data = await response.json()
      return data;
    } catch (err) {
      return console.log(err);
    }
  }
);

export const addTemplateServer = createAsyncThunk(
  "emailTemplate/updateEmailTemplate", async (params) => {
    try {
      const response = await fetch(Constants.Api.commonUrl+'AddTemplateJSON?Token='+`${params.userToken}`+'&Name='+`${params.templateName}`+'&Subject='+`${params.subject}`+'&Active='+`${params.templateStatus}`+'&Message='+`${params.messageresult}`, {
        method: 'GET'
      });
      const data = await response.json()
      return data;
    } catch (err) {
      return console.log(err);
    }
  }
);
  
  

  
  

  

  

const teamsSlice = createSlice({
    name: 'emailTemplate',
    initialState,
    reducers: {
        // setUserName: (state, action) => {
        //     state.userName = action.payload
        // },
        // setUserPassword: (state, action) => {
        //   state.password = action.payload
        // }
    },
    extraReducers: {
      [getEmailTemplates.pending] : (state, action) => {
        state.isLoading=true
      },
      [getEmailTemplates.fulfilled] : (state, action) => {
        state.isLoading=false
        // state.emailTemplatesJson=action.payload
      },
      [getEmailTemplates.rejected] : (state, action) => {
        
      },

     
    }
})


export const {  } = teamsSlice.actions;

export default teamsSlice.reducer;