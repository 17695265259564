import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Constants from "../../constants";

const initialState = {

}

// get prpfole list
export const GetProfileListJsonFunction = createAsyncThunk(
    "getProfileListJsonFunction",async(credentials) => {
        try{
            const response = await fetch(Constants.Api.commonUrl+'ProfileListJSON?Token='+credentials.token,{
                method : 'get'
            })
            const data = await response.json()
            return data
        }
        catch(err){
            return console.log('err',err)
        }
    }
)

// create client information
export const AddClientInformationFunction = createAsyncThunk(
    "client/AddClientInformationFunction", async (credentials) => {
        try{
            const response = await fetch(Constants.Api.commonUrl+'AddClientJSON?Token='+credentials.token+'&ClientName='+credentials['clientInfo']['clientName']+'&Description='+(credentials['clientInfo']['comment'] === undefined ? null : credentials['clientInfo']['comment'])+'&QuestionType='+credentials['clientInfo']['questionnaireType']+'&EffectiveDate='+credentials['clientInfo']['effectiveDate']+'&CloseDate='+credentials['clientInfo']['closeDate']+'&AutoPublishProfile='+(credentials['clientInfo']['autoPublishProfile'] === undefined ? null : credentials['clientInfo']['autoPublishProfile'])+'&ContactName=null&EmailAddress=null&Telephone=null',{
                method : 'get'
            })
            const data = await response.json()
            return data
        }
        catch(err){
            return console.log('err',err)
        }
    }
)

// update client information
export const UpdateClientInformationFunction = createAsyncThunk(
    'updateClientInformation', async(credentials) => {
        console.log("update client info",credentials)
        try{
            const response = await fetch(Constants.Api.commonUrl+'UpdateClientJSON?Token='+credentials.token+'&ClientID='+(credentials['clientInfo']['clientId'] === '' ? null : credentials['clientInfo']['clientId'])+'&ClientName='+(credentials['clientInfo']['clientName'] === '' ? null : credentials['clientInfo']['clientName'])+'&Description='+(credentials['clientInfo']['comment'] === '' ? null : credentials['clientInfo']['comment'] )+'&QuestionType='+(credentials['clientInfo']['questionnaireType'] === '' ? null : credentials['clientInfo']['questionnaireType'])+'&EffectiveDate='+(credentials['clientInfo']['effectiveDate'] === '' ? null : credentials['clientInfo']['effectiveDate'])+'&CloseDate='+(credentials['clientInfo']['closeDate'] === '' ? null : credentials['clientInfo']['closeDate'])+'&AutoPublishProfile='+(credentials['clientInfo']['autoPublishProfile'] === '' ? null : credentials['clientInfo']['autoPublishProfile'])+'&ContactName=NULL&EmailAddress=NULL&Telephone=NULL',{
                method : 'get'
            })
            const data = await response.json()
            return data
        }
        catch(err){
            return console.log('err',err)
        }
    }
)

export const ClientStructureSlice = createSlice({
    name : 'setup-clientstructure',
    initialState,
    reducers : {

    },
    extraReducers : {

    }
})

export const {} = ClientStructureSlice.actions
export default ClientStructureSlice.reducer