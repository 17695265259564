import React from 'react';
import { DataGrid, gridClasses, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { alpha, styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import './grid-container.css';
import { Stack } from '@mui/material';
const ODD_OPACITY = 0.2;
function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
    </GridToolbarContainer>
  );
}
const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-selected': {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity,
      ),
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
          theme.palette.action.selectedOpacity +
          theme.palette.action.hoverOpacity,
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
    '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
      borderRight: `1px solid ${theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'
        }`,
    },
    '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
      borderBottom: `1px solid ${theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'
        }`,
    },
    '& .MuiDataGrid-cell': {
      color:
        theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.65)',
    },
    '& .MuiPaginationItem-root': {
      borderRadius: 0,
    },
  },
}));

const CommonDataGrid = React.memo((props) => {
  const [pageSize, setPageSize] = React.useState(props.rowPerPageShow == true ? props.rowperpageValue : 50);
  return (
    <Grid
      container
      justifyContent={{ xs: 'center', sm: 'center', md: 'center', lg: 'center', xl: 'center' }}
      alignItems="center"
    >
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <div className='grid-cmn-class' style={{ height: props.valueht == undefined ? props.normalgridheight == true ? '300px' : 'calc(100vh - 195px)' : props.valueht }}>
          <StripedDataGrid
            loading = {props.loading}
            rows={props.rows}
            className={props.hideHeader ? 'hide-header' : ''}
            columns={props.columns}
            pageSize={pageSize}
            autoHeight = {props.autoHeight}
            headerHeight={props.hideHeader ? 1 : 56}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[50, 80, 100]}
            pagination
            hideFooterPagination={props.hideFooterPagination !== undefined ? true : false}
            hideFooter={props.hideFooter}
            checkboxSelection={props.showCheckBox}
            disableSelectionOnClick
            onSelectionModelChange={(ids) => props.checkBoxSelected(ids)}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
            }
            rowHeight = {40}
            onRowClick={props.rowClick}
            // components={{
            //   Toolbar: CustomToolbar,
            // }}
            getRowHeight={() => { 
              if(props.rowHeightAuto){
                return 'auto'
              }
            }}
            components={{
              NoRowsOverlay: () => (
                <Stack height="100%" alignItems="center" justifyContent="center">
                  {window.gt('No Data Found')}
                </Stack>
              )
            }}
          />
        </div>
      </Grid>
    </Grid>
  )
})

export default CommonDataGrid