import React, { useState, useEffect } from "react";
import '../common-layout-wrapper/layout-wrapper.css';
import { useDispatch, useSelector } from "react-redux";
import Header from "../common-header/Header";
import QuestionnaireHeader from "../../protected-component/sent-questionnaire/questionnaire-header/QuestionnaireHeader";
import Navbar from "../common-navbar/Navbar";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PersonIcon from '@mui/icons-material/Person';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import HistoryDialog from "../../protected-component/history-dialog/HistoryDialog";
import { resetSlice } from "../../../features/senQuestionnaire/sendQuestionnaireSlice";
import { reset, ProfileStatusFunction, FeedbackStatusFunction } from "../../../features/profile-status/profileStatusSlice";
import { HistoryJSON,setDrawerAction } from "../../../features/login/loginSlice";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const LayoutWrapper = (props) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()
    const [open, setOpen] = React.useState(false);
    const [layoutActivation, setLayoutActivation] = useState(false)
    const [showSurveyHeader, setShowSurveyHeader] = useState(false)
    const [historyData, setHistoryData] = useState({
        openHistoryDialog: false,
        historyData: []
    })
    const [notificationAnchorEl, setNotificiationAnchorEl] = React.useState(null);
    const menuOpen = Boolean(notificationAnchorEl);
    const notificationOpen = (event) => {
        setNotificiationAnchorEl(event.currentTarget);
    };
    const notificationClose = () => {
        setNotificiationAnchorEl(null);
    };

    const handleDrawerOpen = () => {
        sessionStorage.setItem("drawerOpen", "true");
        
        dispatch(setDrawerAction(true))
        setOpen(true);
    };
    const handleDrawerClose = () => {
        sessionStorage.setItem("drawerOpen", "false");
        dispatch(setDrawerAction(false))
        setOpen(false);
    };
    useEffect(() => {
        if (location.pathname === "/" || location.pathname === '/mfa' || location.pathname === '/surveyForm/' || location.pathname === '/surveyQuestions' || location.pathname === '/surveyCompleted' || location.pathname === '/expiredLink' || location.pathname === '/pdf' || location.pathname === '/forgotPsd' || location.pathname === '/crthankyou' || location.pathname === '/mlthankyou' || location.pathname === '/sendquestionnarie' || location.pathname === '/QuestionnaireSurvey' || location.pathname === '/completed' || location.pathname === '/creditNotify' || location.pathname === '/materialNotify' || location.pathname === '/surveyForm') {
            setLayoutActivation(true)
            if (location.pathname === '/QuestionnaireSurvey' || location.pathname === '/sendquestionnarie' || location.pathname === '/completed' || location.pathname === '/expiredLink' || location.pathname === '/surveyForm' || location.pathname === '/surveyQuestions' ) {
                setShowSurveyHeader(true)
            }
            else {
                setShowSurveyHeader(false)
            }
        }
        else {
            setLayoutActivation(false)
        }

        if (location.pathname !== '/sendQuestionnaire') {
            dispatch(resetSlice())
        }

        if (location.pathname !== '/setup') {
            dispatch(reset())
        }

        dispatch(FeedbackStatusFunction({ token: sessionStorage.getItem('token'), status: 'I', client: '%', statusPeriod: 'ALL' })).then(res => {
            let grid = res['payload']['Result']['FeedbackStatusDetail']
            if (grid.length > 0 && grid != '[]') {
                grid.map((item, i) => {
                    return item['id'] = i
                })
                sessionStorage.setItem('completeCount',grid.length)
            }
            else if (typeof grid === 'object' && typeof grid !== null) {
                grid['id'] = '1';
                sessionStorage.setItem('completeCount',[grid].length)
            }
            else {
                sessionStorage.setItem('completeCount',0)
            }
        }).catch(err => {
            console.log("Feedback status JSON err", err)
        })

        dispatch(ProfileStatusFunction({ token: sessionStorage.getItem('token'), status: 'I', client: '%', statusPeriod: 'ALL' })).then(res => {
            let grid = res['payload']['Result']['ProfileStatusDetail']
            if (grid.length > 0 && grid != '[]') {
                grid.map((item, i) => {
                    return item['id'] = i
                })
                sessionStorage.setItem('incompleteCount',grid.length)
            }
            else if (typeof grid === 'object' && typeof grid !== null) {
                grid['id'] = '1';
                sessionStorage.setItem('incompleteCount',[grid].length)
            }
            else {
                sessionStorage.setItem('incompleteCount',0)
            }
        }).catch(err => {
            console.log("profile status JSON err", err)
        })
    }, [location])

    const navigateOne = () => {
        navigate('dashboard', { replace: false })
    }
    const navigateTwo = () => {
        navigate('search', { replace: false })
    }
    const navigateThree = () => {
        navigate('profileStatus', { replace: false })
    }
    const navigatePreference = () => {
        navigate('preference', { replace: false })
    }
    const navigateInvoiceCredits = () => {
        navigate('invoiceCredits', { replace: false })
    }
    const navigationFive = () => {
        setOpen(true)
        // navigate('setup', { replace: false })
    }
    const navigateReport = () => {
        navigate('reports', { replace: false })
    }
    const navigateTeams = () => {
        navigate('teams', { replace: false })
    }
    const resourcenavigate = () => {
        navigate('resource', { replace: false })
    }
    const massDeletenavigate = () => {
        navigate('massdelete', { replace: false })
    }
    const navigateAdminResource = () => {
        navigate('adminResource', { replace: false })
    }
    const adminSysLoookups = () => {
        navigate('adminSyslookup', { replace: false })
    }
    const navigateLanguages = () => {
        navigate('adminSyslanguage', { replace: false })
    }
    const navigateFieldValidation = () => {
        navigate('adminSysFieldValidation', { replace: false })
    }
    const navigateHelpSummary = () => {
        navigate('AdminSystemsHelpSummary', { replace: false })
    }
    const toggleDialog = () => {
        dispatch(HistoryJSON({ token: sessionStorage.getItem('token') })).then(res => {
            if (res['payload']['Result']['History'] !== '[]') {
                setHistoryData({ ...historyData, openHistoryDialog: true, historyData: res['payload']['Result']['History'] })
            }
            else {
                setHistoryData({ ...historyData, openHistoryDialog: true, historyData: [] })
            }
        })
    }

    const structureTrigger = () => {
        handleDrawerOpen()
    }
    const triggerStructure = () => {
        navigate('setup', { replace: false })
    }
    const clientTrigger = () => {
        navigate('sendQuestionnaire', { replace: false })
    }
    const contactTrigger = () => {
        navigate('emailTemplate', { replace: false })
    }
    const emailTrigger = () => {
        navigate('teams', { replace: false })
    }
    const adminCompanyTrigger = () => {
        navigate('adminCompany', {replace: false})
    }
    const adminInvoices = () =>{
        navigate('invoices', {replace:false})
    }
    const adminMaterials = () =>{
        navigate('materials', {replace:false})
    }
    // child calls end

    const DrawerHeader = styled('div')(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    }));

    let Title = () => {
        return (
            <div className="line-height-normal">
                <p className="mar-0 text-uppercase">team</p>
                <p className="mar-0 pd-l-2px font-size-12px">workology</p>
            </div>
        )
    }

    const notificationDropdown = () => {
        return (
            <Menu
                id="basic-menu"
                anchorEl={notificationAnchorEl}
                open={menuOpen}
                onClose={notificationClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={() => profileNavigation('feedback')}><span className="m-r-5 cust-badge">{sessionStorage.getItem('completeCount')}</span>{window.gt( "Feedback")}</MenuItem>
                <MenuItem onClick={() => profileNavigation('incomplete')}><span className="m-r-5 cust-badge">{sessionStorage.getItem('incompleteCount')}</span> {window.gt("Profiles")}</MenuItem>
            </Menu>
        )
    }

    const profileNavigation = (param) => {
        navigate('/profileStatus', { replace: true, state: { tab: param } })
        setNotificiationAnchorEl(null);
    }

    return (
        <Box sx={{ display: 'flex' }}>
            {notificationDropdown()}
            {showSurveyHeader && <QuestionnaireHeader />}
            {!layoutActivation && <Header handleLogoutRoutes={()=>props.handleRoutes()} notificationCount={Number(sessionStorage.getItem('completeCount')) + Number(sessionStorage.getItem('incompleteCount'))} notificationTrigger={notificationOpen} showMenu={true} draweOpen={handleDrawerOpen} title={<Title />} notificationIcon={<NotificationsIcon />} personIcon={<PersonIcon />} vertIcon={<MoreVertIcon />} historyBtn={toggleDialog} open={open} />}
          
            {!layoutActivation && <Navbar triggerStructure={triggerStructure} navigateOne={navigateOne} navigateTwo={navigateTwo} navigationFive={navigationFive} navigatePreference={navigatePreference} navigateTeams={navigateTeams} navigateReport={navigateReport} navigateInvoiceCredits={navigateInvoiceCredits} navigateThree={navigateThree} setupStructureTrigger={structureTrigger} setupClientTrigger={clientTrigger} setupContactTrigger={contactTrigger} setupEmailTrigger={emailTrigger} adminCompanyTrigger = {adminCompanyTrigger} resourcenavigate={resourcenavigate} massDeletenavigate={massDeletenavigate} drawerClose={handleDrawerClose} open={open} navigateAdminResource={navigateAdminResource} navigateAdminInvoices={adminInvoices} navigateAdminMaterails={adminMaterials} navigateAdminSysLoookups={adminSysLoookups} navigateLanguages={navigateLanguages} navigateFieldValidation={navigateFieldValidation} navigateHelpSummary={navigateHelpSummary}
             /> }
            <Box component="main" sx={(!layoutActivation || showSurveyHeader) ? { flexGrow: 1, p: 3 } : { flexGrow: 0, p: 0 }} className="w-100 bg-clr-F4F6FD p-0">
                {(!layoutActivation || showSurveyHeader) && <DrawerHeader />}
                <div maxWidth="xl" className={(!layoutActivation || showSurveyHeader) ? 'p-0' : 'css-19r6kue-MuiContainer-root p-0'}>
                    {props.children}
                </div>
            </Box>
            <HistoryDialog title={window.gt('History Dialog')} data={historyData['historyData']} open={historyData['openHistoryDialog']} close={() => {
                setHistoryData({ ...historyData, openHistoryDialog: false, historyData: [] })
            }} />
        </Box>
    )
}

export default LayoutWrapper