import React, { lazy, Suspense, useState } from 'react';
import LayoutWrapper from './component/common-component/common-layout-wrapper/LayoutWrapper';
import CmnBackdrop from './component/common-component/common-backdrop/CmnBackdrop';
import { Routes, BrowserRouter, Route, Navigate } from 'react-router-dom';

const Login = lazy(() => import('./container/loginContainer/loginContainer'))
const LoginMfa = lazy(() => import('./container/LoginMfaContainer/LoginMfaContainer'));
const Dashboard = lazy(() => import('./container/DashboardContainer/DashboardContainer'))
const ProfileStatus = lazy(() => import('./container/ProfileStatusContainer/ProfileStatusContainer'))
const InvoiceCredits = lazy(() => import('./container/InvoiceCreditsContainer/InvoiceCreditsContainer'))
const Reports = lazy(() => import('./container/ReportContainer/ReportContainer'))
const Setup = lazy(() => import('./container/SetupContainer/SetupContainer'))
const SendQuestionnaire = lazy(() => import('./container/SendQuestionnaireContainer/SendQuestionnaire'))
const Teams = lazy(() => import('./container/TeamsContainer/TeamsContainer'))
const AdminInvoices = lazy(()=>import('./container/AdminInvoiceContainer/AdminInvoiceContainer'))
const AdminMaterials = lazy(()=>import('./container/AdminMaterialContainer/AdminMaterialContainer'))
const EmailTemplate = lazy(() => import('./container/EmailTemplateContainer/EmailTemplate'));
const Search = lazy(() => import('./container/SearchContainer/SearchContainer'))
const Resource = lazy(() => import('./container/resourcesContainer/resourceComponents'));
const Preference = lazy(() => import('./container/PreferenceContainer/preferenceContainer'))
const GlobalSearch = lazy(() => import('./container/GlobalSearchContainer/GlobalSearchContainer'))
const QuestionnaireSurvey = lazy(() => import('./container/questionarrieSurveyContainer/questionnarieSurvey'))
const MassDelete = lazy(() => import('./container/MassDelete/massDelete'));
const Paypal = lazy(() => import('./component/protected-component/invoiceCredits/paypal/Paypal'))
const CrThankyouPage = lazy(() => import('./container/ThankyoupageContainer/CrThankyouPage'))
const MlThankyouPage = lazy(() => import('./container/ThankyoupageContainer/MlThankyouPage'))
const SendQuestionnarie = lazy(() => import('./container/sentQuestionarrie/sentQuestionnarie'))
const CreditNotifyContainer = lazy(() => import('./container/CreditNotifyContainer/CreditNotifyContainer'))
const InvoicePaymentNotifyContainer = lazy(() => import('./container/InvoicePaymenyNotifyContainer/InvoicePaymentNotifyContainer'))
const MaterialNotifyContainer = lazy(() => import('./container/MaterialNotifyContainer/MaterialNotifyContainer'))
const SurveyCompletedContainer = lazy(() => import('./container/SurveyCompletedContainer/SurveyCompletedContainer'))
const AdminResourceContainer = lazy(() => import('./container/AdminResourceContainer/adminResourceContainer'))
const AdminCompanyContainer = lazy(() => import('./container/AdminCompanyContainer/AdminCompanyContainer'))
const AdminSystemsLookupContainer = lazy(() => import('./container/AdminSystemsLookupContainer/lookupContainer/adminSystemLookupContainer'))
const AdminSystemsLanguageContainer = lazy(() => import('./container/AdminSystemsLookupContainer/languageContainer/adminSystemLanguageContainer'))
const AdminSystemsFieldValidation = lazy(() => import('./container/AdminSystemsLookupContainer/FieldValidationContainer/adminSystemFieldValidationContainer'))
const AdminSystemsHelpSummary = lazy(() => import('./container/AdminSystemsLookupContainer/HelpSummaryContainer/adminSystemHelpSummaryContainer'))
const Questionnaire = lazy(() => import('./container/DnaQuestionnaireContainer/QuestionnaireContainer/Questionnaire'))
const SurveyQuestions = lazy(() => import('./container/DnaQuestionnaireContainer/SurveyQuestionContainer/SurveyQuestions'))
const ExpiredLinkContainer = lazy(() => import('./container/ExpiredLinkContainer/ExpiredLinkContainer'))

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(true);


  const handleLoginRoutes = () => {
    setIsAuthenticated(true);
  };

  const handleLogoutRoutes = () => {
    setIsAuthenticated(false);
  };
  const PrivateRoute = ({ children }) => {
    return sessionStorage.getItem('token')!=null ? children : <Navigate to="/" />;
  };

  return (
    <BrowserRouter>
      <LayoutWrapper handleRoutes={handleLogoutRoutes}>
        <Suspense fallback={<div><CmnBackdrop backdrop={true} /></div>}>
          <Routes>
            <Route path="/" element={<Login handleLoginRoutes={handleLoginRoutes} />} />
            <Route 
              path="/mfa" 
              element={
                <PrivateRoute>
                  <LoginMfa />
                </PrivateRoute>
              } 
            />
            <Route
              path="/dashboard"
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
            />

            <Route path='/invoiceCredits' element={
              <PrivateRoute>
                <InvoiceCredits />
              </PrivateRoute>

            } />
            <Route path='/reports' element={
              <PrivateRoute>
                <Reports />
              </PrivateRoute>
            } />
            <Route path='/setup' element={
              <PrivateRoute>
                <Setup />
              </PrivateRoute>
            } />
            <Route path='/sendQuestionnaire' element={
              <PrivateRoute>
                <SendQuestionnaire />
              </PrivateRoute>
            } />
            <Route path='emailTemplate' element={
              <PrivateRoute>
                <EmailTemplate />
              </PrivateRoute>
            } />
            <Route path='/profileStatus' element={
              <PrivateRoute>
                <ProfileStatus />
              </PrivateRoute>
            } />
            <Route path='/teams' element={
              <PrivateRoute>
                <Teams />
              </PrivateRoute>
            } />
            <Route path='/search' element={
              <PrivateRoute>
                <Search />
              </PrivateRoute>
            } />
            <Route path='/resource' element={
              <PrivateRoute>
                <Resource />
              </PrivateRoute>
            } />
            <Route path='/preference' element={
              <PrivateRoute>
                <Preference />
              </PrivateRoute>
            } />
            <Route path='/globalSearch' element={
              <PrivateRoute>
                <GlobalSearch />
              </PrivateRoute>
            } />
            <Route path='/QuestionnaireSurvey' element={
                <QuestionnaireSurvey />
            } />
            <Route path='/massdelete' element={
              <PrivateRoute>
                <MassDelete />
              </PrivateRoute>
            } />
            <Route path='/Paypal' element={
              // <PrivateRoute>
                <Paypal />
              // </PrivateRoute>
            } />
            <Route path='/crthankyou' element={
              <PrivateRoute>
                <CrThankyouPage />
              </PrivateRoute>
            } />
            <Route path='/mlthankyou' element={
              <PrivateRoute>
                <MlThankyouPage />
              </PrivateRoute>
            } />
            <Route path='/creditNotify' element={
              <PrivateRoute>
                <CreditNotifyContainer />
              </PrivateRoute>
            } />
            <Route path='/materialNotify' element={
              <PrivateRoute>
                <MaterialNotifyContainer />
              </PrivateRoute>
            } />
            <Route path='invoicePayNotify' element={
              <PrivateRoute>
                <InvoicePaymentNotifyContainer />
              </PrivateRoute>
            } />
            <Route path='/sendquestionnarie' element={
                <SendQuestionnarie />
            } />
            <Route path='/completed' element={
                <SurveyCompletedContainer />
            } />
            <Route path='/adminResource' element={
              <PrivateRoute>
                <AdminResourceContainer />
              </PrivateRoute>
            } />
            <Route path='/adminCompany' element={
              <PrivateRoute>
                <AdminCompanyContainer />
              </PrivateRoute>
            } />
            <Route path='/adminSyslookup' element={
              <PrivateRoute>
                <AdminSystemsLookupContainer />
              </PrivateRoute>
            } />
            <Route path='/adminSyslanguage' element={
              <PrivateRoute>
                <AdminSystemsLanguageContainer />
              </PrivateRoute>
            } />
            <Route path='/adminSysFieldValidation' element={
              <PrivateRoute>
                <AdminSystemsFieldValidation />
              </PrivateRoute>
            } />
            <Route path='/AdminSystemsHelpSummary' element={
              <PrivateRoute>
                <AdminSystemsHelpSummary />
              </PrivateRoute>
            } />
             <Route path='/invoices' element={
              <PrivateRoute>
                <AdminInvoices/>
              </PrivateRoute>
            } />
            <Route path='/materials' element={
              <PrivateRoute>
                <AdminMaterials/>
              </PrivateRoute>
            } />
            <Route path='/surveyForm' element={
                <Questionnaire/>
            } />
            <Route path='/surveyQuestions' element={
                <SurveyQuestions/>
            } />
            <Route path='/expiredLink' element={
                <ExpiredLinkContainer />
            } />
          </Routes>
        </Suspense>
      </LayoutWrapper>
    </BrowserRouter>
  );
}

export default App;
