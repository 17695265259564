import React from 'react'
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import  Logo  from '../../../../assets/loginlogo.png'
import './questionnaire-header.css'

const QuestionnaireHeader = React.memo(() => {
    return (
        <div className='bg-header'>
            <MuiAppBar position="fixed">
                <Toolbar>
                    <img src={Logo} alt = "4d-logo" className='logo'/>
                    <Box sx={{ flexGrow: 0 }} />

                    <Box sx={{ flexGrow: 1 }}>

                    </Box>
                </Toolbar>
            </MuiAppBar>
        </div>
    )
})

export default QuestionnaireHeader