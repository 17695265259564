import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Constants from "../../constants";

const initialState = {
    clientList : []
}

export const PreferenceJsonFunction = createAsyncThunk(
    'personSearch/reportsSearchSlice',async(credentials) => {
        try{
            const response = await fetch(Constants.Api.commonUrl+'AccountJSON?Token='+credentials.token,{
                
                method : 'GET'
            })
            const data = await response.json()
            return data
        }
        catch(err){
            return console.log('AccountJSON',err)
        }
    }
)

export const PreferencePostJsonFunction = createAsyncThunk(
    'personSearch/reportsSearchSlice',async(credentials) => {
        console.log("credentials",credentials)
        try{
            const response = await fetch(Constants.Api.commonUrl+'UpdatePreferenceJSON?Token='+credentials.token+'&StartUp='+credentials.startUp+'&SentFromMe='+credentials.sentFromMe+'&NotifyMe='+credentials.notifyMe+'&SecurityQuestion='+credentials.securityQuestion+'&SecurityAnswer='+credentials.securityAnswer+'&EmailByUser='+credentials.emailSentByuser+'&Language='+credentials.Language,{
                
                method : 'GET'
            })
            const data = await response.json()
            return data
        }
        catch(err){
            return console.log('AccountJSON',err)
        }
    }
)


export const PreferenceSlice = createSlice({
    name : 'PreferenceSlice',
    initialState,
    reducers : {
        // triggerRefresh : (state, action) => {
        // }
    },
    extraReducers : {
        [PreferenceJsonFunction.pending] : (state, action) => {

        },
        [PreferenceJsonFunction.fulfilled] : (state, action) => {
            state.clientList = action['payload']['Result']['Client'];
        },
        [PreferenceJsonFunction.rejected] : (state, action) => {
            
        },
        [PreferencePostJsonFunction.pending] : (state, action) => {

        },
        [PreferencePostJsonFunction.fulfilled] : (state, action) => {
            state.clientList = action['payload']['Result']['Client'];
        },
        [PreferencePostJsonFunction.rejected] : (state, action) => {
            
        },
        
    }
})

export const {} = PreferenceSlice.actions
export default PreferenceSlice.reducer


