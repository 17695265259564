import * as React from 'react';
import { styled, alpha, createTheme } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Input from '@mui/material/Input';
import { AccountJSONFn } from '../../../features/login/loginSlice'
import moment from 'moment'
import { globalSearchServer, changeSearchValue, changeCheckValue } from '../../../features/globalSearch/globalSearchSlice'

import LanguageIcon from '@mui/icons-material/Language';
import MoreIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import '../common-header/header.css';
import Badge from '@mui/material/Badge';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import HistoryIcon from '@mui/icons-material/History';
import LogoutIcon from '@mui/icons-material/Logout';
import NotificationsIcon from '@mui/icons-material/Notifications';
import FormatIndentIncreaseIcon from '@mui/icons-material/FormatIndentIncrease';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router-dom';
import { ProfileStatusFunction } from '../../../features/dashboard/DashboardSlice';
import { useDispatch, useSelector } from 'react-redux';
import { LanguageListJsonFunction } from '../../../features/person-search/personSearchSlice';
import { SystemLanguageListJSON, setLanguageCollection } from '../../../features/admin-system/adminSystemSlice';
import CmnBackdrop from '../common-backdrop/CmnBackdrop';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 6,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: '#DCDCDC',
  paddingRight: 3,
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),

    width: "auto"
  }
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    width: "100%",

  }
}));



const drawerWidth = 240;


const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default function Header(props) {
  // const theme = useTheme();
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1450,
      },
    },
  });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [searchValue, setSearchValue] = React.useState('')
  const [pickValue, setPickValue] = React.useState('All')
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null)
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const xlResolutionIdentifier = useMediaQuery(theme.breakpoints.down('xl'));
  const lgResolutionIdentifier = useMediaQuery(theme.breakpoints.down('lg'));
  const mdResolutionIdentifier = useMediaQuery(theme.breakpoints.down('md'));
  const smResolutionIdentifier = useMediaQuery(theme.breakpoints.down('sm'));
  const xsResolutionIdentifier = useMediaQuery(theme.breakpoints.down('xs'));
  const [largeRes, setLargeRes] = React.useState(false)
  const [smallRes, setSmallRes] = React.useState(false)
  const [lastSubmitData, setLastSubmitData] = React.useState('')
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { accountJson } = useSelector((store) => store.login);
  const { checkValue } = useSelector((store) => store.globalSearch);
  const [language, setLanguage] = React.useState('ENG');
  const [languageList, setLanguageList] = React.useState([])
  const [languageLoader, setLangaugeLoader] = React.useState(false)

  React.useEffect(() => {
    dispatch(ProfileStatusFunction({ token: sessionStorage.getItem('token') })).then(res => {
      setLastSubmitData(res['payload']['Result']['ProfileStatus']['Last_Submitted'])
    })
    dispatch(LanguageListJsonFunction({ token: sessionStorage.getItem('token'), category: 'PUB_LANGUAGE' })).then(res => {
      if (res['payload']['Result']['Option'] !== '[]') {
        setLanguageList(res['payload']['Result']['Option'])
      }
      else {
        setLanguageList([])
      }
    }).catch(err => {
    })
    dispatch(AccountJSONFn(sessionStorage.getItem('token'))).then((res) => {
      setLangaugeLoader(true)
      sessionStorage.setItem('access-rights',res['payload']['Result']['Account']['Security_Group'])
      if (res['payload']['Result']['Account'] !== '[]') {
        dispatch(SystemLanguageListJSON({token: sessionStorage.getItem('token'), language: res['payload']['Result']['Account']['Default_Language'], code: '%'})).then(languageResponse => {
          if (languageResponse['payload']['Result']['Language'] !== '[]' && languageResponse['payload']['Result']['Language'].length) {
            let temp = {};
            languageResponse['payload']['Result']['Language'].forEach((item) => {
              temp[item.Code.toUpperCase()] = item['Text_String']
            })
            dispatch(setLanguageCollection(temp))
            setLangaugeLoader(false)
          }
        })
      }
    })
  }, [])

  React.useEffect(() => {
    if (xlResolutionIdentifier || lgResolutionIdentifier) {
      setLargeRes(true)
      setSmallRes(false)
    }
    else {
      setLargeRes(false)
      setSmallRes(true)
    }
  }, [xlResolutionIdentifier, lgResolutionIdentifier, mdResolutionIdentifier, smResolutionIdentifier, xsResolutionIdentifier])

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    navigate('/preference')
  };

  const logout = () => {
    props.handleLogoutRoutes()
    sessionStorage.clear()
  navigate('',{replace:true})
  }
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >

      <MenuItem onClick={handleMobileMenuClose}>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <Badge badgeContent={5} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleMobileMenuClose}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Preference</p>
      </MenuItem>
      <MenuItem onClick={()=>{
        logout()
        }}>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={0} color="error">
            <LogoutIcon />
          </Badge>
        </IconButton>
        <p>Logout</p>
      </MenuItem>
    </Menu>
  );

  const searchTrigger = (event) => {
    event.preventDefault()
    dispatch(changeCheckValue(!checkValue))
    dispatch(changeSearchValue(searchValue))
    if (searchValue == '') {
      alert('Enter the search value')
    }
    else {
      let tabs = []
      dispatch(globalSearchServer({ token: sessionStorage.getItem('token'), type: 'All', Criteria: searchValue })).then((res) => {
        sessionStorage.setItem('selectedIndex','')
        sessionStorage.setItem('selectedChilIndex','')
        let person = res?.payload?.SearchResult?.Result[0].Person != '[]' && Array.isArray(res?.payload?.SearchResult?.Result[0].Person) ?  res?.payload?.SearchResult?.Result[0].Person : typeof res?.payload?.SearchResult?.Result[0].Person === 'object' && typeof res?.payload?.SearchResult?.Result[0].Person !== null?[res?.payload?.SearchResult?.Result[0].Person]: []
        let client = res?.payload?.SearchResult?.Result[1].Client != '[]' && Array.isArray(res?.payload?.SearchResult?.Result[1].Client) ? res?.payload?.SearchResult?.Result[1].Client : typeof res?.payload?.SearchResult?.Result[1].Client === 'object' && typeof res?.payload?.SearchResult?.Result[1].Client !== null?[res?.payload?.SearchResult?.Result[1].Client]: []
        let Invoice = res?.payload?.SearchResult?.Result[2].Invoice != '[]' && Array.isArray(res?.payload?.SearchResult?.Result[2].Invoice) ? res?.payload?.SearchResult?.Result[2].Invoice : typeof res?.payload?.SearchResult?.Result[2].Invoice === 'object' && typeof res?.payload?.SearchResult?.Result[2].Invoice !== null?[res?.payload?.SearchResult?.Result[2].Invoice]: []
        let Material = res?.payload?.SearchResult?.Result[3].Material != '[]' && Array.isArray(res?.payload?.SearchResult?.Result[3].Material) ? res?.payload?.SearchResult?.Result[3].Material : typeof res?.payload?.SearchResult?.Result[3].Material === 'object' && typeof res?.payload?.SearchResult?.Result[3].Material !== null?[res?.payload?.SearchResult?.Result[3].Material]: []

        let personCount = person == '[]' ? 0 : Array.isArray(person) ? person.length : 1
        let clientCount = client == '[]' ? 0 : Array.isArray(client) ? client.length : 1
        let InvoiceCount = Invoice == '[]' ? 0 : Array.isArray(Invoice) ? Invoice.length : 1
        let MaterialCount = Material == '[]' ? 0 : Array.isArray(Material) ? Material.length : 1
          person.map((item, i) =>
          item['id'] = i
        )
         client.map((item, i) =>
          item['id'] = i
        )
         Invoice.map((item, i) =>
          item['id'] = i
        )
        Material.map((item, i) =>
          item['id'] = i
        )


        if (pickValue == 'All') {

          tabs = [
            {
              index: 0,
              key: 'item_one',
              name: "Person",
              count: personCount,
              data: person

            },
            {
              index: 1,
              key: 'item_two',
              name: "Client",
              count: clientCount,
                data:  client
            },
            {
              index: 2,
              key: 'item_three',
              name: "Invoice",
              count: InvoiceCount,
                data:  Invoice
            },
            {
              index: 3,
              key: 'item_four',
              name: "Material",
              count: MaterialCount,
                data:  Material
            },

          ]
        }
        else if (pickValue == 'Person') {
          tabs = [
            {
              index: 0,
              key: 'item_one',
              name: "Person",
              count: personCount,
              data: person
            },]
        }
        else if (pickValue == 'Client') {
          tabs = [
            {
              index: 0,
              key: 'item_one',
              name: "Client",
              count: clientCount,
              data: client
            },]
        }
        else if (pickValue == 'Material') {
          tabs = [
            {
              index: 0,
              key: 'item_one',
              name: "Material",
              count: MaterialCount,
              data: Material
            },]
        }
        else if (pickValue == 'Invoice') {
          tabs = [
            {
              index: 0,
              key: 'item_one',
              name: "Invoice",
              count: InvoiceCount,
              data: Invoice
            },]
        }

        navigate("/globalSearch", {
          state: {

            page: tabs,
            tabName: pickValue,
            searchValue: searchValue
          },
        });
        console.log("/globalSearch", {
          state: {

            page: tabs,
            tabName: pickValue,
            searchValue: searchValue
          },
        })
        // setTimeout(() => {
          window.location.reload()
        // }, [1000])

      })
    }

  }
  return (
    <div className={smallRes ? "bg-header" : "sm-header"}>
      <CmnBackdrop key={JSON.stringify(languageLoader)} backdrop = {languageLoader} />
      <AppBar position="fixed" open={props.open} className="headermb" style={{zIndex: languageLoader ? 0 : 'initial'}}>
        <Toolbar>
          {props.showMenu && <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={props.draweOpen}
            edge="start"
            sx={{
              justifyContent: "space-between",
              marginRight: 2,
              ...(props.open && { display: 'none' }),
            }}
          >
            <FormatIndentIncreaseIcon />
          </IconButton>}
          <Box sx={{ flexGrow: 0 }} />
          <FormControl className='serch-dr' >
            <Select
              disableUnderline
              variant='standard'
              className='serchvalue'
              labelId="demo-select-small"
              id="demo-select-small"
              value={pickValue}
              label="Age"
              onChange={(val) => setPickValue(val.target.value)}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left"
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left"
                },
                getContentAnchorEl: null
              }}
            >
              <MenuItem value={'All'}>{window.gt('All')}</MenuItem>
              <MenuItem value={'Person'}>{window.gt('Person')}</MenuItem>
              <MenuItem value={'Client'}>{window.gt('Client')}</MenuItem>
              <MenuItem value={'Material'}>{window.gt('Material')}</MenuItem>
              <MenuItem value={'Invoice'}>{window.gt('Invoice')}</MenuItem>
            </Select>
          </FormControl>
          <Search className='search-container'>

            <FormControl variant="standard">
              <form onSubmit={searchTrigger}>
                <Input onChange={(event) => setSearchValue(event.target.value)} disableUnderline
                  id="input-with-icon-adornment"
                />
              </form>
            </FormControl>
            <IconButton onClick={searchTrigger} type="submit" fullWidth variant="contained" color="primary">
              <SearchIcon />
            </IconButton>
          </Search>
          <Box sx={{ flexGrow: 1 }}>

          </Box>
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <div style={{ paddingTop: '5px', paddingRight: '15px' }}>
              <div>{sessionStorage.getItem('companyName')}</div>
              <b>{sessionStorage.getItem('username')}</b> {'-'} <span style={{ color: '#1976d1' }}>{accountJson != undefined && accountJson.hasOwnProperty('Account') ? moment(accountJson.Account.Last_Signon).format('DD/MM/YYYY') : moment(lastSubmitData).format('DD/MM/YYYY')}</span>
            </div>
            <div className='line-height-normal bg-gray'>
              {props.userInformation}
            </div>
            {props.showMenu && <IconButton
              className="bg-gray"
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              color="inherit"
              onClick={props.notificationTrigger}
            >
              <Badge className="bg-gray" onClick={props.notificationTrigger} badgeContent={Number(sessionStorage.getItem('completeCount')) + Number(sessionStorage.getItem('incompleteCount'))} color="secondary">
                {props.notificationIcon}
              </Badge>
            </IconButton>}
            <IconButton
              className="bg-gray"
              size="large"
              color="inherit"
              id="basic-button"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
            >
              {props.personIcon}
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem onClick={() => {
                handleClose()
              }}>{window.gt('Preference')}</MenuItem>
              <MenuItem onClick={() => logout()}>{window.gt('Logout')}</MenuItem>
            </Menu>
            <IconButton
              className="bg-gray"
              size="large"
              color="inherit"
              id="basic-button"
              onClick={() => props.historyBtn()}
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}>
              <HistoryIcon />
            </IconButton>
          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </Box>

        </Toolbar>
      </AppBar>
      {renderMobileMenu}
    </div>
  );
}
