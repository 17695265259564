import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Constants from "../../constants";

const initialState = {
    loading: false,
    showloader:false,
}

export const adminUserAccountJsonFunction = createAsyncThunk(
    'admin/userAccountPicklistJSON',async(credentials) => {
        try{
            const response = await fetch(Constants.Api.commonUrl+'PickListJSON?Token='+credentials.token+'&Category='+credentials.category+'&Language='+(credentials.language === undefined ? 'ENG' : credentials.language)+'&Gender=M',{
                method : 'get' 
            })
            const data = await response.json()
            return data
        }
        catch(err){
            return console.log('AccountJSON',err)
        }
    }
)


export const UserAccountsDetailsJSON = createAsyncThunk(
    'admin/userAccountDetailJSON',async(credentials) => {
        try{
            const response = await fetch(Constants.Api.commonUrl+'UserAccountsJSON?Token='+credentials.token+'&CID='+credentials.cId+'&UserName='+credentials.userName,{
                method : 'get' 
            })
            const data = await response.json()
            return data
        }
        catch(err){
            return console.log('AccountJSON',err)
        }
    }
)

export const AddUserAccountsDetailsJSON = createAsyncThunk(
    'admin/addUserAccountDetailJSON',async(credentials) => {
        try{
            const response = await fetch(Constants.Api.commonUrl+'AddUserProfileJSON?Token='+credentials.token+'&CID='+credentials.cId+'&Country='+credentials.formData.CountryCode+'&Name='+credentials.formData.Name+'&SecurityGroup='+credentials.formData.SecurityGroup+'&SecurityLevel='+credentials.formData.SecurityLevel+'&Password='+credentials.formData.password+'&EmailAddress='+credentials.formData.EmailAddress+'&ExpiryDate='+credentials.formData.AccountExpiry+'&Disabled='+credentials.formData.Disabled+'&Language='+credentials.formData.Language+'&Question='+credentials.formData.SecurityQuestion+'&Answer='+credentials.formData.SecurityAnswer+'&EmailByUser='+credentials.formData.EmailSentforme+'&NotifySubmission='+credentials.formData.NotidyUserofSubmission+'&TimeOut='+credentials.formData.SessionTimeOut,{
                method : 'get' 
            })
            const data = await response.json()
            return data
        }
        catch(err){
            return console.log('AccountJSON',err)
        }
    }
)

export const UpdateUserAccountsDetailsJSON = createAsyncThunk(
    'admin/UpdateUserAccountsDetailsJSON',async(credentials) => {
        try{
            const response = await fetch(Constants.Api.commonUrl+'UpdateUserProfileJSON?Token='+credentials.token+'&CID='+credentials.cId+'&Country='+credentials.formData.CountryCode+'&UserName='+credentials.formData.UserName+'&Name='+credentials.formData.Name+'&SecurityGroup='+credentials.formData.SecurityGroup+'&SecurityLevel='+credentials.formData.SecurityLevel+'&Password='+credentials.formData.password+'&EmailAddress='+credentials.formData.EmailAddress+'&ExpiryDate='+credentials.formData.AccountExpiry+'&Disabled='+credentials.formData.Disabled+'&Language='+credentials.formData.Language+'&Question='+credentials.formData.SecurityQuestion+'&Answer='+credentials.formData.SecurityAnswer+'&EmailByUser='+credentials.formData.EmailSentforme+'&NotifySubmission='+credentials.formData.NotidyUserofSubmission+'&TimeOut='+credentials.formData.SessionTimeOut,{
                method : 'get' 
            })
            const data = await response.json()
            return data
        }
        catch(err){
            return console.log('AccountJSON',err)
        }
    }
)


export const UpdateResourceDocumentDetailsJSON = createAsyncThunk(
    'admin/UpdateResourceDocumentDetailsJSON', async(credentials) => {
        try{
            const response = await fetch(Constants.Api.commonUrl+'UpdateResourceJSON?Token='+credentials.token+'&CID='+credentials.cId+'&DocumentID='+credentials.formData.documentID+'&DocumentType='+credentials.formData.documentType+'&Name='+credentials.formData.Name,'&Active='+credentials.formData.Active+'&Filename='+credentials.formData.fileName+'&Description='+credentials.formData.description+'&Sort='+credentials.formData.Order,'&Category='+credentials.formData.CategoriesOne+'&Category2='+credentials.formData.CategoriesTwo+'&Category3='+credentials.formData.CategoriesThree,{
                method : 'get' 
            })
            const data = await response.json()
            return data
        }
        catch(err){
            return console.log("company json err",err)
        }
    }
)


export const addeResourceDocumentDetailsJSON = createAsyncThunk(
    'admin/addeResourceDocumentDetailsJSON', async(credentials) => {
        try{
            const response = await fetch(Constants.Api.commonUrl+'AddResourceJSON?Token='+credentials.token+'&CID='+credentials.cId+'&Name='+credentials.formData.Name,'&Active='+credentials.formData.Active+'&Filename='+credentials.formData.fileName+'&Description='+credentials.formData.description+'&Sort='+credentials.formData.Order,'&Category='+credentials.formData.CategoriesOne+'&Category2='+credentials.formData.CategoriesTwo+'&Category3='+credentials.formData.CategoriesThree,{
                method : 'get' 
            })
            const data = await response.json()
            return data
        }
        catch(err){
            return console.log("company json err",err)
        }
    }
)

export const adminuserAccountSlice = createSlice({
    name : 'admin/adminuserAccountSlice',
    initialState,
    reducers : {},
    extraReducers : {
        [UserAccountsDetailsJSON.pending] : (state, action) => {
            state.showloader=true;
        },
        [UserAccountsDetailsJSON.fulfilled] : (state, action) => {
            state.showloader=false;
        },
        [UserAccountsDetailsJSON.rejected] : (state, action) => {
            state.showloader=false;
        },
        [AddUserAccountsDetailsJSON.pending] : (state, action) => {
            state.loading = true;
        },
        [AddUserAccountsDetailsJSON.fulfilled] : (state, action) => {
            state.clientList = action['payload']['Result']['Client'];
            state.loading = false;
        },
        [AddUserAccountsDetailsJSON.rejected] : (state, action) => {
            state.loading = false;
            
        },
        [UpdateUserAccountsDetailsJSON.pending] : (state, action) => {
            state.loading = true;

        },
        [UpdateUserAccountsDetailsJSON.fulfilled] : (state, action) => {
            state.clientList = action['payload']['Result']['Client'];
            state.loading = false;
        },
        [UpdateUserAccountsDetailsJSON.rejected] : (state, action) => {
            state.loading = false;
            
        },
        [UpdateResourceDocumentDetailsJSON.pending] : (state, action) => {
            state.loading = true;

        },
        [UpdateResourceDocumentDetailsJSON.fulfilled] : (state, action) => {
            state.clientList = action['payload']['Result']['Client'];
            state.loading = true;
        },
        [UpdateResourceDocumentDetailsJSON.rejected] : (state, action) => {
            state.loading = false;
            
        },
        [addeResourceDocumentDetailsJSON.pending] : (state, action) => {
            state.loading = true;

        },
        [addeResourceDocumentDetailsJSON.fulfilled] : (state, action) => {
            state.clientList = action['payload']['Result']['Client'];
            state.loading = false;
        },
        [addeResourceDocumentDetailsJSON.rejected] : (state, action) => {
            state.loading = false;
        },
        
    }
})

export const {} = adminuserAccountSlice.actions
export default adminuserAccountSlice.reducer
