import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Constants from "../../constants";

const initialState = {
    clientList : [],
    showLoader: false,
    csvLoader : false
}

export const ReportsJsonFunction = createAsyncThunk(
    'personSearch/reportsSearchSlice',async(credentials) => {
        try{
            const response = await fetch(Constants.Api.commonUrl+'ReportsJSON?Token='+credentials.token,{
                
                method : 'GET'
            })
            const data = await response.json()
            return data
        }
        catch(err){
            return console.log('personSearchResponseJSON',err)
        }
    }
)

export const ReportsGroupingJsonFunction = createAsyncThunk(
    'personSearch/reportsSearchSliceCount',async(credentials) => {
        try{
            const response = await fetch(Constants.Api.commonUrl+'ReportDetailJSON?Token='+credentials.token+'&Report='+credentials.status+'&Top='+credentials.top,{
                
                method : 'GET'
            })
            const data = await response.json()
            return data
        }
        catch(err){
            return console.log('personSearchResponseJSON',err)
        }
    }
)

export const ReportsGroupingJsonFunctionCSV = createAsyncThunk(
    'personSearch/reportsSearchSliceFullData',async(credentials) => {
        try{
            const response = await fetch(Constants.Api.commonUrl+'ReportDetailJSON?Token='+credentials.token+'&Report='+credentials.status+'&Top='+credentials.top,{
                
                method : 'GET'
            })
            const data = await response.json()
            return data
        }
        catch(err){
            return console.log('personSearchResponseJSON',err)
        }
    }
)


export const reportSearchSlice = createSlice({
    name : 'reportsSearchSlice',
    initialState,
    reducers : {
        // triggerRefresh : (state, action) => {
        // }
    },
    extraReducers : {
        [ReportsJsonFunction.pending] : (state, action) => {
            state.showLoader = true
        },
        [ReportsJsonFunction.fulfilled] : (state, action) => {
            state.showLoader = false
            state.clientList = action['payload']['Result']['Client'];
        },
        [ReportsJsonFunction.rejected] : (state, action) => {
            state.showLoader = false
            
        },
        [ReportsGroupingJsonFunctionCSV.pending] : (state, action) => {
            state.csvLoader=true
        },
        [ReportsGroupingJsonFunctionCSV.fulfilled] : (state, action) => {
            state.csvLoader = false
        },
        [ReportsGroupingJsonFunctionCSV.rejected] : (state, action) => {
            state.csvLoader = false
            
        },

        [ReportsGroupingJsonFunction.pending] : (state, action) => {
            state.showLoader = true
        },
        [ReportsGroupingJsonFunction.fulfilled] : (state, action) => {
            state.showLoader = false
            state.clientList = action['Result'];
        },
        [ReportsGroupingJsonFunction.rejected] : (state, action) => {
            state.showLoader = false
            
        },
       
    }
})

export const {} = reportSearchSlice.actions
export default reportSearchSlice.reducer


