import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import './dialog-wrapper.css';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { DialogTitle } from '@mui/material';
import { DialogContent } from '@mui/material';
import { DialogActions } from '@mui/material';
import BtnIconContent from '../common-content-btn/BtnContent';
import { CircularProgress } from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const DialogWrapper = React.memo((props) => {
    const [show, setShow] = React.useState(true)
    const [loaderActivate, setLoaderActivate] = React.useState(false);
    const [loaderActivateTwo, setLoaderActivateTwo] = React.useState(false);
    const theme = useTheme();
    const xlResolutionIdentifier = useMediaQuery(theme.breakpoints.down('xl'));
    const lgResolutionIdentifier = useMediaQuery(theme.breakpoints.down('lg'));
    const mdResolutionIdentifier = useMediaQuery(theme.breakpoints.down('md'));
    const smResolutionIdentifier = useMediaQuery(theme.breakpoints.down('sm'));
    const xsResolutionIdentifier = useMediaQuery(theme.breakpoints.down('xs'));
    React.useEffect(() => {
      setShow(props.show)
    },[props.show])
    React.useEffect(() => {
      try {
        if (props.loaderActivate !== undefined) {
          setLoaderActivate(props.loaderActivate)
        } else {
          setLoaderActivate(false)
        }
      } catch (err) {
        
      }
    },[props.loaderActivate])
    React.useEffect(() => {
      try {
        if (props.loaderActivateTwo !== undefined) {
          setLoaderActivateTwo(props.loaderActivateTwo)
        } else {
          setLoaderActivateTwo(false)
        }
      } catch (err) {
        
      }
    },[props.loaderActivateTwo])
    return (
      <div>
        <form>
          <Dialog
            className={xsResolutionIdentifier ? '' : smResolutionIdentifier ? '' : mdResolutionIdentifier ? '' : lgResolutionIdentifier ? 'dialog-position-setter' : xlResolutionIdentifier ? 'dialog-position-setter' : 'dialog-position-setter'}
            fullScreen
            open={props.open}
            onClose={props.close}
            TransitionComponent={Transition}
          >
          <DialogTitle sx = {{display : "flex", alignItems : "center"}}>
                <Typography sx={{ flex: 1 }} variant="h6" component="div" className='text-capitalize'>
                  {window.gt(props.title)}
                </Typography>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={props.close}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
          </DialogTitle>
          <Divider/>
          <DialogContent>
              {props.children}
          </DialogContent>
          <Divider/>
          {(props.showActions === undefined || props.showActions) && <DialogActions>
              {show && <BtnIconContent disabled={props?.disabled} click = {props.clickBtnNameOne} color = {'primary'}>
                {loaderActivate ? <CircularProgress size={25} color="inherit" /> : window.gt(props.btnNameOne)}
              </BtnIconContent>}
              <BtnIconContent  click = {props.clickBtnNameTwo} color = {'primary'}>
                {loaderActivateTwo ? <CircularProgress size={25} color='inherit' /> : window.gt(props.btnNameTwo)}
              </BtnIconContent>
          </DialogActions>}
          </Dialog>
        </form>
      </div>
    );
  })

  export default DialogWrapper